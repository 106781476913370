import Config from "./config";

class ConstantsTool {
    static BASE_URL_API = "https://api2.worksheetzone.org/api/";
    static RESOURCES_URL = 'https://resources.k12worksheet.com/';
    static S3_TEMPLATE_RESOURCE_URL = 'https://auto-content-templates.s3.us-west-2.amazonaws.com/';
    static DEFAULT_IMAGE = 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg';
    static DEFAULT_TEXT = 'EMPTY';
    static API_URL = process.env.REACT_APP_API_URL;
    static LOCAL_STORAGE_PREFIX = '_bee1203_learningmaterials_';
    static LOCAL_STORAGE_TOKEN = this.LOCAL_STORAGE_PREFIX + 'token';
    static LIMIT_RESOURCE = 40;
    static WIDTH_A4 = 794;
    static HEIGHT_A4 = 1123;
    static WIDTH_STORE_THUMBNAIL = 500;
    static HEIGHT_STORE_THUMBNAIL = 500;
    static HEIGHT_TES_THUMBNAIL = 375;
    static INIT_WIDTH_A4 = 794;
    static INIT_HEIGHT_A4 = 1123;
    static MAX_HEIGHT_WORD_SEARCH = 1123 * 0.6;
    static EXPORT_WIDTH_A4 = 2480;
    static EXPORT_HEIGHT_A4 = 3508;
    static SMALL_SIZE_MODULE = 329;
    static MEDIUM_SIZE_MODULE = 479;
    static LARGE_SIZE_MODULE = 639;
    static WORD_LIST_START_Y = 830;
    static MAX_Y_WORD_LIST = 1000;
    // static START_POSITION_MODULE = 270;
    static RATIO_FONT = 0.93;
    static WORD_SEARCH_BORDER_END_X = 770;
    static PADDING_RIGHT_TEXT = 7;
    static PADDING_BOTTOM_TEXT = 4;
    static DEFAULT_WORD_LIST_FONT_SIZE = 20;
    static DEFAULT_STUDENT_INFO_FONT_SIZE = 24;
    static DEFAULT_FONT = "Nunito";
    static STUDENT_INFO_START_X = 50;
    static STUDENT_INFO_START_Y = 40;
    static STUDENT_INFO_1_WIDTH =
        ((this.WIDTH_A4 - this.STUDENT_INFO_START_X * 2) * 2) / 3; // Minus Padding left and right and take 2/3 of that width
    static STUDENT_INFO_2_WIDTH =
        ((this.WIDTH_A4 - this.STUDENT_INFO_START_X * 2) * 1) / 3; // Minus Padding left and right and take 1/3 of that width
    static STUDENT_INFO_DEFAULT_VALUE = {
        PORTRAIT: {
            0: "Name:________________________________",
            1: "Grade:____________",
        },
        LANDSCAPE: {
            0: "Name:________________________________________________",
            1: "Grade:_____________________",
        },
    };

    static MAX_SIZE_UPLOAD_BYTES = 1048576;
    static DUPLICATEid_ACTION = 1;
    static DELETEid_ACTION = 2;
    static SEND_TO_BACKid_ACTION = 3;
    static SEND_BACKWARDid_ACTION = 4;
    static BRING_TO_FRONTid_ACTION = 5;
    static BRING_FORWARDid_ACTION = 6;
    static AUTO_AJUST_COLUMNSid_ACTION = 7;
    static AUTO_AJUST_ROWSid_ACTION = 8;
    static SETTING_CUSTOM_TABLEid_ACTION = 9;
    static LOCKid_ACTION = 10;
    static ALIGN_LINKED_ZONESid_ACTION = 11;

    static MORE_ACTION_WIDTH = 108;
    static BORDER_Y = 25;
    static BORDER_X = 27;

    static BORDER_HEIGHT = this.HEIGHT_A4 - this.BORDER_Y - 38; // PADDING BOTTOM: 38
    static BORDER_WIDTH = this.WIDTH_A4 - this.BORDER_X * 2;
    static PADDING_BORDER = 20;

    static DEFAULT_TEXT_FILL_IN_BLANK_FONT_SIZE = 24;
    static DEFAULT_Y_WORD_LIST_TITLE_NEW_PAGE = 70;
    static DEFAULT_Y_WORD_DIRECTIONS = 800;
    static DEFAULT_Y_MODULE = 270;
    static MARGIN_TOP_WORD_LIST_LARGE = 10;
    static MARGIN_TOP_WORD_LIST_MEDIUM = 30;

    static RESOURCE_IMAGE = 1;
    static RESOURCE_BACKGROUND = 2;
    static RESOURCE_TEXT_MODULE = 3;
    static RESOURCE_TEXT = 4;

    static TYPE_WORD_DIRECTION = 5;
    static TYPE_WORD_LIST = 6;
    static TYPE_LOGO = 7;
    static TYPE_UNDER_LINE = 8;
    static TYPE_STUDENT_INFO = 9;
    static TYPE_RESOURCE_MODULE = 10;
    static TYPE_RESOURCE_BORDER = 11;
    static TYPE_TEXT_FILL_THE_BLANK = 12;
    static TYPE_WORD_BANK = 13;
    static TYPE_RESOURCE_BACKGROUND_IMAGE = 14;
    static TYPE_RESOURCE_LINE = 15;
    static TYPE_RESOURCE_HANDWRITING = 16;
    static TYPE_RESOURCE_BORDER_FRAMES = 17;
    static TYPE_RESOURCE_NAME_TRACING = 18;
    static TYPE_RESOURCE_SHAPE = 19;
    static TYPE_RESOURCE_LINE_INTERACTIVE = 20;
    static TYPE_RESOURCE_NORMAL_SHAPE = 21;
    static TYPE_RESOURCE_NORMAL_LINE = 22;
    static TYPE_RESOURCE_RECT = 23;
    static TYPE_RESOURCE_CROSSWORD_GRID = 24;
    static TYPE_RESOURCE_CROSSWORD_WORD_LIST = 25;
    static TYPE_RESOURCE_CROSSWORD_WORD_BANK = 26;
    static TYPE_RESOURCE_GROUP_ELEMENT = 27;
    static TYPE_RESOURCE_MATH_FORMULA = 28;
    static TYPE_RESOURCE_TABLE = 29;
    static TYPE_RESOURCE_MATH = 30;
    static TYPE_RESOURCE_WORD_SCRAMBLE = 31;
    static TYPE_RESOURCE_SENTENCE_SCRAMBLE = 32;
    static TYPE_RESOURCE_WORD_BANK_SCRAMBLE = 33;
    static TYPE_TEXT_MULTIPLE_CHOICES = 34;
    static TYPE_RESOURCE_CONVERTED_ICON_SHAPE = 36;
    static TYPE_RESOURCE_ZONE = 50;
    static TYPE_RESOURCE_ZONE_IMAGE = 51;
    static TYPE_RESOURCE_ZONE_STATIC = 52;
    static TYPE_RESOURCE_ZONE_CONTAINER = 53;

    static TEXT_WORD_DIRECTION = "Words are hidden";
    static MARGIN_WORD_LIST = 10;
    static PADDING_WORD_LIST = 4;
    static MARGIN_ROW_WORD_LIST = 4;
    static WORD_LIST_TITLE_HEIGHT_DEFAULT = 35;
    static WORD_DIRECTION_HEIGHT_DEFAULT = 40;
    static WORD_DIRECTION_MARGIN_TOP = 10;

    // Zone field names
    static WORD_COUNT_ZONE = 'word_count';
    static PASSAGE_ZONE = 'passage';
    static SEASON_ICON_ZONE = 'season_icon';
    static CONTAINER_ZONE = 'container';

    // STUDIO
    static TASKCARD_TAG = 362;
    static COLOR_BY_CODE_TAG = 364;
    static TEMPLATE_COLOR_BY_CODE_TAG = 235;
    static COLOR_BY_CODE_USE_WHITE_BG_STEP = 4;
    static MUSIC_COLOR_BY_CODE_TAG = 369;
    static MYSTERY_PICTURE_COLOR_TAG = 368

    // Handwriting
    static HANDWRITING_X = 60;
    static HANDWRITING_FIRST_PAGE_Y = 200;
    static HANDWRITING_OTHER_PAGE_Y = 50;
    static HANDWRITING_WIDTH = this.WIDTH_A4 - this.HANDWRITING_X * 2;
    static TEXT_STYLE = {
        REGULAR: 1,
        DASHED: 2,
        STROKED: 3,
    };
    static LINE_SPACING = {
        1: 40,
        2: 46,
        3: 52,
        4: 58,
        5: 64,
        6: 70,
        7: 76,
        8: 82,
        9: 88,
        10: 94,
    };

    // Multiple choices
    static MULTIPLE_CHOICES_WIDTH = this.WIDTH_A4 - 70 * 2;
    static DEFAULT_ANSWER_WIDTH = ConstantsTool.MULTIPLE_CHOICES_WIDTH / 4 - 10;
    static LINE_HEIGHT_MULTIPLE_CHOICES = 32;
    static MULTIPLE_CHOICES_X = 80;
    static MULTIPLE_CHOICES_FIRST_PAGE_Y = 200;

    static MAX_NUMBER_OF_LINE = 20;
    static FONT_PRINT = 1;
    static FONT_CURSIVE = 2;

    // Name tracing
    static ORIENTATION = {
        PORTRAIT: 1,
        LANDSCAPE: 2,
    };
    static NAME_TRACING_X = 60;
    static NAME_TRACING_FIRST_PAGE_Y = 180;
    static NAME_TRACING_OTHER_PAGE_Y = 50;
    static NAME_TRACING_WIDTH = this.WIDTH_A4 - this.NAME_TRACING_X * 2;

    // Crossword
    static DIRECTIONS = {
        ACROSS: 0,
        DOWN: 1,
    };
    static LAYOUT = {
        DEFAULT: "DEFAULT",
        CLUES_BY_SIDE: "CLUES_BY_SIDE",
        IMAGE_LOWER_RIGHT: "IMAGE_LOWER_RIGHT",
        IMAGE_LOWER_LEFT: "IMAGE_LOWER_LEFT",
        IMAGE_UPPER_RIGHT: "IMAGE_UPPER_RIGHT",
        IMAGE_UPPER_LEFT: "IMAGE_UPPER_LEFT",
    };
    static CROSSWORD_X = 60;
    static CROSSWORD_Y = 140;
    static MAX_WIDTH_GRID_CROSSWORD = this.BORDER_WIDTH - 60;
    static MAX_HEIGHT_GRID_CROSSWORD = 880;
    static MAX_HEIGHT_GRID_CROSSWORD_WITH_IMAGE = 640;
    static CROSSWORD_MAX_Y = this.CROSSWORD_Y + this.MAX_HEIGHT_GRID_CROSSWORD; // Padding bottom
    static CROSSWORD_MAX_Y_WITH_IMAGE =
        this.CROSSWORD_Y + this.MAX_HEIGHT_GRID_CROSSWORD_WITH_IMAGE; // Padding bottom
    static CROSSWORD_OTHER_Y = 50;
    static EMPTY_LETTER = "_";
    static CROSSWORD_STATUS = {
        INIT: -1,
        INCORRECT: 0,
        CORRECT: 1,
    };
    static LINE_THROUGH = "line-through-element";

    static MAX_WIDTH_TEXT_FILL_IN_BLANK = this.BORDER_WIDTH - 60;
    static PADDING_WORD_BANK_VERTICAL = 12;
    static PADDING_WORD_BANK_HORIZONTAL = 18;
    static GAP_ELEMENT_WORD_BANK = 10;
    static MARGIN_BOTTOM_WORD_BANK = 12;
    static GAP_ELEMENT_FILL_THE_BLANK = 10;
    static MAX_Y_BORDER = this.BORDER_Y + this.BORDER_HEIGHT;
    static START_Y_NEW_PAGE_FILL_THE_BLANK =
        this.BORDER_Y + this.GAP_ELEMENT_WORD_BANK * 2;
    static WIDTH_LOGO = 145.5;
    static MAX_WIDTH_GENERATOR = this.BORDER_WIDTH - this.WIDTH_LOGO - 10;
    static INIT_NUMBER_ACTION = 2;

    static FIELDNAME_TOPIC_ZONE = 'icon';

    static changeOrientation(
        orientation?: number,
        width?: number,
        height?: number
    ) {
        let WIDTH_A4 = ConstantsTool.INIT_WIDTH_A4;
        let HEIGHT_A4 = ConstantsTool.INIT_HEIGHT_A4;

        if (width) {
            WIDTH_A4 = width;
        }
        if (height) {
            HEIGHT_A4 = height;
        }
        if (orientation === ConstantsTool.ORIENTATION.LANDSCAPE) {
            [WIDTH_A4, HEIGHT_A4] = [HEIGHT_A4, WIDTH_A4];
        }

        ConstantsTool.WIDTH_A4 = WIDTH_A4;
        ConstantsTool.HEIGHT_A4 = HEIGHT_A4;
        ConstantsTool.BORDER_WIDTH = WIDTH_A4 - ConstantsTool.BORDER_X * 2;
        ConstantsTool.BORDER_HEIGHT = HEIGHT_A4 - ConstantsTool.BORDER_Y - 38;
        ConstantsTool.HANDWRITING_WIDTH =
            WIDTH_A4 - ConstantsTool.HANDWRITING_X * 2;
        ConstantsTool.NAME_TRACING_WIDTH =
            WIDTH_A4 - ConstantsTool.NAME_TRACING_X * 2;
        ConstantsTool.STUDENT_INFO_1_WIDTH =
            ((ConstantsTool.WIDTH_A4 - ConstantsTool.STUDENT_INFO_START_X * 2) *
                2) /
            3;
        ConstantsTool.STUDENT_INFO_2_WIDTH =
            ((ConstantsTool.WIDTH_A4 - ConstantsTool.STUDENT_INFO_START_X * 2) *
                1) /
            3;
    }
    static LINE_HEIGHT_DEFAULT = 1.4;

    static DEFAULT_FRAME_URL = process.env.REACT_APP_URL + 'images/DEFAULT-FRAME.png';
    static DEFAULT_FRAME_ELEMENT = {
        id: '1702842072299_19696802',
        idType: '',
        activityId: '',
        pageIndex: 0,
        x: 0,
        y: 0,
        type: 14,
        worksheet: '',
        width: this.WIDTH_A4,
        height: this.HEIGHT_A4,
        rotate: 0,
        imageAttribute: {
            url: this.DEFAULT_FRAME_URL,
            svgContent: '',
            changeColor: {
                '#000000': '#000000'
            }
        },
        wordList: [],
        isShow: true,
        url: this.DEFAULT_FRAME_URL,
        opacity: 100,
        moduleId: '',
        isGroup: false,
        resourceIds: [],
        backgroundColor: "#FFFFFF"
    };

    static DEFAULT_FRAME_H_URL = process.env.REACT_APP_URL + 'images/DEFAULT-FRAME-H-TASKCARD.png';
    static DEFAULT_FRAME_H_ELEMENT = {
        id: '1702842072299_19616803',
        idType: '',
        activityId: '',
        pageIndex: 0,
        x: 0,
        y: 0,
        type: 14,
        worksheet: '',
        width: 1123,
        height: 794,
        rotate: 0,
        imageAttribute: {
            url: this.DEFAULT_FRAME_H_URL,
            svgContent: '',
            changeColor: {
               '#000000': '#000000'
            }
        },
        wordList: [],
        isShow: true,
        url: this.DEFAULT_FRAME_H_URL,
        opacity: 100,
        moduleId: '',
        isGroup: false,
        resourceIds: [],
        backgroundColor: '#FFFFFF'
    };

    static DEFAULT_HEADLINE = {
        "id": "1702842159772_96529173",
        "idType": "",
        "activityId": "",
        "pageIndex": 0,
        "x": 0,
        "y": 54.666666666666686,
        "type": 4,
        "worksheet": "",
        "width": 794,
        "height": 56,
        "rotate": 0,
        "textAttribute": {
            "offset": 0,
            "direction": 0,
            "blur": 0,
            "transparency": 0,
            "intensity": 0,
            "thickness": 0,
            "roundness": 0,
            "spread": 0,
            "color": "#212121",
            "textEffectId": 0,
            "fontFamily": "KGSecondChancesSolid",
            "align": "center",
            "isBold": true,
            "isItalic": false,
            "fontColor": "#212121",
            "fontSize": 40,
            "content": "DEMO HEADLINE",
            "isAppliedAutoFontSize": true,
            "autoFill": false,
            "lineHeight": 1.4,
            "underline": false,
            "cmd": {
                "id": 0,
                "content": ""
            },
            "subTexts": [],
            "letterSpacing": 0,
            "fontColors": []
        },
        "wordList": [],
        "isShow": true,
        "opacity": 1,
        "moduleId": "",
        "isGroup": false,
        "resourceIds": [],
        "backgroundColor": "#FFFFFF",
        isHeadline: true
    };

    static NAME_TEXT_ITEM = {
        "id": "1729149382232_96229302",
        "idType": "",
        "activityId": "",
        "pageIndex": 0,
        "x": 30,
        "y": 10,
        "type": 4,
        "worksheet": "",
        "width": 300,
        "height": 30,
        "rotate": 0,
        "textAttribute": {
            "offset": 0,
            "direction": 0,
            "blur": 0,
            "transparency": 0,
            "intensity": 0,
            "thickness": 0,
            "roundness": 0,
            "spread": 0,
            "color": "#212121",
            "textEffectId": 0,
            "fontFamily": "ComicNeueSansID",
            "align": "left",
            "isBold": true,
            "isItalic": false,
            "fontColor": "#212121",
            "fontSize": 20,
            "content": "Name:.........................",
            "autoFill": false,
            "isAppliedAutoFontSize": true,
            "lineHeight": 1.4,
            "underline": false,
            "cmd": {
                "id": 0,
                "content": ""
            },
            "subTexts": [],
            "letterSpacing": 0,
            "fontColors": []
        },
        "wordList": [],
        "isShow": true,
        "opacity": 1,
        "moduleId": "",
        "isGroup": false,
        "resourceIds": [],
        "backgroundColor": "#FFFFFF",
        "isNameText": true
    };

    static DEFAULT_TEXT_LINKED_ZONE = {
        id: null,
        idType: "",
        activityId: "",
        pageIndex: 0,
        x: null,
        y: null,
        type: Config.RESOURCE_TEXT,
        worksheet: "",
        width: null,
        height: null,
        rotate: 0,
        textAttribute: null,
        wordList: [],
        isShow: true,
        opacity: 1,
        moduleId: "",
        isGroup: false,
        resourceIds: [],
        backgroundColor: "#FFFFFF",
        customZoneID: null,
    }

    static DEFAULT_TEXT_LINKED_IMAGE = {
        id: null,
        idType: "",
        activityId: "",
        pageIndex: 0,
        x: null,
        y: null,
        type: ConstantsTool.RESOURCE_IMAGE,
        worksheet: "",
        width: null,
        height: null,
        rotate: 0,
        imageAttribute: null,
        wordList: [],
        isShow: true,
        opacity: 1,
        moduleId: "",
        isGroup: false,
        resourceIds: [],
        backgroundColor: "#FFFFFF",
        customZoneID: null,
    }

    static FRAME_ORIENTATION_CHOICES = {
        'vertical': 'vertical',
        'horizontal': 'horizontal',
    }

    static OPEN_SET_FIELD_ZONE_MODAL_ACTION = 'OPEN_SET_FIELD_ZONE_MODAL_ACTION';
    static CHANGE_ZONE_NAME_ACTION = 'CHANGE_ZONE_NAME';
    static DELETE_USING_ZONE_IN_TEMPLATE_ACTION = 'DELETE_USING_ZONE_IN_TEMPLATE';
    static ADD_NEW_ZONE_WHEN_TEMPLATE_IS_USING_ACTION = 'ADD_NEW_ZONE_WHEN_TEMPLATE_IS_USING_ACTION';
    static WARNING_WHEN_TEMPLATE_IS_USING_IN_IDEA = 'ATTENTION! The current template is using in at least 1 idea.';
    static WARNING_FOR_MANAGER__WHEN_TEMPLATE_IS_USING_IN_IDEA = 'The current template is using in at least 1 idea. Be very careful if you want to make changes';
    static DEFAULT_ZONE_FIELD_NAMES = [
        'title',
        'directions',
        'passage',
        'question',
        'answer',
        'choice',
        'page_index',
        'page_value',
        'icon',
        'word_count',
    ];

    static TEMPLATE_TYPES = {
        'template': 'template',
        'storeThumbnail': 'store_thumbnail_template',
        'answerSheet': 'answer_sheet_template',
    };

    static TEMPLATE_TYPES_IN_DATABASE = {
        'template': 'normal',
        'store_thumbnail_template': 'thumbnail_store',
        'answer_sheet_template': 'answer_sheet'
    }

    static PAGE_TYPES = {
        'page': 'page',
        'thumbnail': 'thumbnail',
        'answerSheet': 'answer_sheet',
        'answerKey': 'answer_key',
    }
}
export default ConstantsTool;
