import { CSSProperties } from "react";
import { RootState } from "../redux/store";
import Constants from "../resource-user/utils/constants";
import Cookies from "../resource-user/utils/cookies";
import { EventTracking, sendEvent } from "../resource-user/utils/event";
import Routes from "../resource-user/utils/routes";
import {
    IActivity,
    IWorkSheetCreator,
    ListStyle,
} from "../shared/models/WorkSheetCreator";
import { ICrossword, Word } from "../shared/models/crossword";
import {
    IPageWorksheetNew,
    PageWorksheetNew,
} from "../shared/models/pageWorksheetNew";
import { IPuzzle, Puzzle } from "../shared/models/puzzle";
import { QuestionActivity } from "../shared/models/questionActivity";
import { IResourceItem, ResourceItem } from "../shared/models/resourceItem";
import {
    IImageAttribute,
    IResourceItemNew,
    ITextAttribute,
    ImageAttribute,
    ResourceItemNew,
    TextAttribute,
} from "../shared/models/resourceItemNew";
import { IFont, IWorksheet } from "../shared/models/worksheet";
import ConstantsTool from "../shared/utils/ConstantsTool";
import Config from "../shared/utils/config";
import CreateWSConfig from "./create-ws-config";
import { getSizeBoxText, getTextWidthHeightTool } from "./draw";
import { IShapeAttribute } from "../shared/models/shapeAttribute";
import { getListCommands } from "../resource-user/utils/shape";
import { SizeA4 } from "../shared/utils/sizeA4";
import { updateManyResourceByType, updateResourceItem, updateTempTemplateZone } from "../redux/reducers/createWorksheet";
import { updateDataAPI } from "./query";

export const isProduction = () => {
    return process.env.REACT_APP_IS_PRODUCTION == "true";
};

export const getPrefixImage = () => {
    return process.env.PUBLIC_URL ?? "";
};
export const convertFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
export const floorNumber = (number: number) => {
    return Number(number.toFixed(3));
};
export const getColorsFromXML = (xmlContent: string) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlContent, "text/xml");
    const colors = [];
    const regex = /#[a-z0-9A-Z]*/g;
    let m;
    if (xml.querySelector("style")) {
        const styles = xml.querySelector("style").textContent;

        while ((m = regex.exec(styles)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            m.forEach((match) => {
                if (!colors.includes(match)) {
                    colors.push(match);
                }
            });
        }
    }
    while ((m = regex.exec(xmlContent)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        m.forEach((match) => {
            if (!colors.includes(match)) {
                colors.push(match);
            }
        });
    }
    return colors.filter((c) => c != "#");
};

export const getTextEffectStyle = (item: IResourceItemNew) => {
    let effectData = item.textAttribute;
    if (!item?.textAttribute?.fontColor)
        item.textAttribute.fontColor = "#212121";
    switch (item.textAttribute.textEffectId) {
        case CreateWSConfig.EFFECT_NONEid: {
            return {};
        }
        case CreateWSConfig.EFFECT_SHADOWid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    ${handleCalculateWithFontSize(
                        effectData.blur * 0.015,
                        item
                    )}px
                    ${effectData.color}, ${effectData.transparency / 100})`,
            };
        }
        case CreateWSConfig.EFFECT_LIFTid: {
            return {
                textShadow: `0px
                    ${handleCalculateWithFontSize(0.45, item)}px
                    ${handleCalculateWithFontSize(
                        effectData.intensity * 0.02925 + 0.45,
                        item
                    )}px
                    rgba(0, 0, 0, ${(effectData.intensity * 0.55 + 5) / 100})`,
            };
        }
        case CreateWSConfig.EFFECT_HOLLOWid: {
            return {
                WebkitTextStroke: handleCalculateWithFontSize(
                    (effectData.thickness - 1) * 0.0075 + 0.0825,
                    item
                ),
            };
        }
        case CreateWSConfig.EFFECT_SPLICEid: {
            return {
                WebkitTextStroke: handleCalculateWithFontSize(
                    (effectData.thickness - 1) * 0.0075 + 0.0825,
                    item
                ),
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}`,
            };
        }
        case CreateWSConfig.EFFECT_ECHOid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}, 0.5),
                                        ${
                                            handleCalculateWithFontSize(
                                                effectData.offset * 0.015,
                                                item
                                            ) *
                                            -Math.sin(
                                                toRadians(effectData.direction)
                                            ) *
                                            2
                                        }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) *
                        Math.cos(toRadians(effectData.direction)) *
                        2
                    }px
                    0px
                    ${effectData.color}, 0.3)`,
            };
        }
        case CreateWSConfig.EFFECT_GLITCHid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.0075,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    rgb(0, 255, 255),
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * Math.sin(toRadians(effectData.direction))
                    }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * -Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}`,
            };
        }
        case CreateWSConfig.EFFECT_NEONid: {
            return {
                filter: `drop-shadow(rgba(24, 40, 83, 0.95)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.000904 + 0.150901,
                      item
                  )}px)
                  drop-shadow(rgba(47, 56, 83, 0.75)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.00452 + 0.754507,
                      item
                  )}px)
                  drop-shadow(rgba(47, 56, 83, 0.44)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.01356 + 2.26352,
                      item
                  )}px)`,
                color: `rgb(${effectData.intensity * 2.02 + 53},
                  ${effectData.intensity * 1.97 + 58},
                  ${effectData.intensity * 1.8 + 75})`,
            };
        }
        case CreateWSConfig.EFFECT_BACKGROUNDid: {
            return {
                fill: `#212121`,
                cornerRadius: effectData.roundness,
                padding: effectData.spread,
                opacity: effectData.transparency / 100,
                backgroundColor: effectData.color,
            };
        }
        default: {
            return {};
        }
    }
};
export const generatorCustomizeTextStyle = (
    resourceItem: IResourceItemNew
): CSSProperties => {
    let isGenerator = resourceItem.idType?.includes("ws_generator");
    return {
        whiteSpace: isGenerator ? "nowrap" : "pre-wrap",
        overflow: isGenerator ? "hidden" : "unset",
        textOverflow: isGenerator ? "ellipsis" : "unset",
    };
};
export const toRadians = (angleDegrees) => {
    return angleDegrees * (Math.PI / 180);
};

const handleCalculateWithFontSize = (value, item) => {
    if (item.fontSize < 10) {
        return value;
    }
    const newValue = (value / 9) * (item.fontSize - 9) + value;
    return newValue;
};

export const getSizeFromShape = (row, column, ratio, widthCell = 34.5) => {
    widthCell = widthCell * ratio;
    let totalWidth = column * widthCell;
    let totalHeight = row * widthCell;
    return { totalWidth, totalHeight };
};
export const convertPageWs = ({
    newResourceItems,
    worksheet,
    orientation,
}: {
    newResourceItems: IResourceItemNew[];
    worksheet?: IWorksheet;
    orientation?: number;
}) => {
    let mappingIndexResource = {};
    let maxIndex = 0;
    if (newResourceItems.length) {
        maxIndex = newResourceItems.reduce((pre, next) => {
            if (pre.pageIndex > next.pageIndex) {
                return pre;
            }
            return next;
        }, newResourceItems[0]).pageIndex;
    }
    for (let i = 0; i <= maxIndex; i++) {
        mappingIndexResource[i.toString()] = [];
    }
    newResourceItems.forEach((el) => {
        let key = el.pageIndex.toString();
        if (mappingIndexResource[key]) {
            mappingIndexResource[key].push(el);
        }
    });
    let newPages: IPageWorksheetNew[] = [];
    let listPageIndex = Object.keys(mappingIndexResource);
    for (let i = 0; i < listPageIndex.length; i++) {
        let index = parseInt(listPageIndex[i]);
        let resourceItemsPage: any[] = mappingIndexResource[index];
        let sourcePage = null;
        setWidthHeightTemplate({
            worksheet,
            resourceItems: resourceItemsPage,
            orientation,
        });

        newPages.push(
            new PageWorksheetNew({
                id:
                    new Date().getTime().toString() +
                    "_" +
                    Math.floor(Math.random() * 99999999).toString(),
                width: ConstantsTool.WIDTH_A4,
                height: ConstantsTool.HEIGHT_A4,
                resourceItems: resourceItemsPage
                    .filter((el) => {
                        return (
                            !el.idType?.includes("logo") &&
                            !el.idType?.includes("ws_generator")
                        );
                    })
                    .map((el) => {
                        return new ResourceItemNew({
                            ...el,
                            // type:
                            //     el.type ==
                            //     ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
                            //         ? ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
                            //         : el.type,
                        });
                    }),
                sourcePage: sourcePage,
            })
        );
    }
    return newPages;
};
export const getPageWsResources = (
    newResourceItems: IResourceItemNew[],
    pageWs: IPageWorksheetNew[]
) => {
    let mappingIndexResource = {};
    newResourceItems.forEach((el) => {
        let key = el.pageIndex.toString();
        if (mappingIndexResource[key]) {
            mappingIndexResource[key].push(el);
        } else {
            mappingIndexResource[key] = [el];
        }
    });

    let listPageIndex = Object.keys(mappingIndexResource);
    for (let i = 0; i < listPageIndex.length; i++) {
        let index = parseInt(listPageIndex[i]);
        let resourceItemsPage: any[] = mappingIndexResource[index];
        pageWs[i].resourceItems = resourceItemsPage.map(
            (resource) => new ResourceItemNew({ ...resource })
        );
        pageWs[i].id = generateIdFromDateTime();
    }
};
export const generateIdFromDateTime = (prefix = "") => {
    return (
        prefix +
        new Date().getTime().toString() +
        "_" +
        Math.floor(Math.random() * 99999999).toString()
    );
};
export const canDragElement = (
    resourceItem: IResourceItemNew | IResourceItemNew
) => {
    return (
        !resourceItem.idType?.includes("word_list_title") &&
        !resourceItem.idType?.includes("ws_generator") &&
        resourceItem.type !== ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_MODULE &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_BORDER &&
        resourceItem.type !== ConstantsTool.RESOURCE_BACKGROUND &&
        resourceItem.type !== ConstantsTool.TYPE_WORD_DIRECTION &&
        resourceItem.type !== ConstantsTool.TYPE_WORD_LIST &&
        resourceItem.type !== ConstantsTool.TYPE_STUDENT_INFO &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
        resourceItem.type !== ConstantsTool.TYPE_LOGO &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK &&
        resourceItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST
    );
};
export const resourceCanEditTextStyle = (
    currentElement: IResourceItemNew | IResourceItemNew,
    resourceItems?: IResourceItemNew[]
) => {
    const funcCheck = (type: number) => {
        return (
            type === ConstantsTool.RESOURCE_TEXT ||
            type === ConstantsTool.RESOURCE_TEXT_MODULE ||
            type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
            type === ConstantsTool.TYPE_STUDENT_INFO ||
            type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
            type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
            type === ConstantsTool.TYPE_RESOURCE_MATH ||
            type === ConstantsTool.TYPE_RESOURCE_TABLE ||
            type === ConstantsTool.TYPE_WORD_BANK ||
            type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA ||
            type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
            type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
            type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE ||
            type === ConstantsTool.TYPE_RESOURCE_ZONE ||
            type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER
        );
    };
    if (currentElement.resourceIds?.length && resourceItems?.length) {
        let count = 0;
        for (let resourceItem of resourceItems) {
            if (resourceCanEditTextGroup(resourceItem)) {
                count++;
            }
        }
        return count > 0;
    }
    return funcCheck(currentElement.type);
};
export const wordListBlockResource = (resourceItem: IResourceItemNew) => {
    return (
        resourceItem.idType?.includes("word_list_title") ||
        resourceItem.type === ConstantsTool.TYPE_WORD_DIRECTION ||
        resourceItem.type === ConstantsTool.TYPE_WORD_LIST
    );
};
export const listArrayCanResize = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        !currentElement.idType?.includes("word_list_title") &&
        !currentElement.idType?.includes("ws_generator") &&
        currentElement.type !== ConstantsTool.TYPE_WORD_DIRECTION &&
        currentElement.type !== ConstantsTool.TYPE_WORD_LIST &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_MODULE &&
        currentElement.type !== ConstantsTool.TYPE_STUDENT_INFO &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
        currentElement.type !== ConstantsTool.TYPE_LOGO &&
        currentElement.type !== ConstantsTool.TYPE_WORD_BANK &&
        currentElement.type !== ConstantsTool.RESOURCE_BACKGROUND &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES &&
        currentElement.type !==
            ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID &&
        currentElement.type !== ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES
        // &&
        // currentElement.type !==
        //     ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK
    );
};
export const canRotateElement = (currentElement: IResourceItemNew) => {
    return currentElement.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE;
};
export const canDeleteElement = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        currentElement.type === ConstantsTool.RESOURCE_IMAGE ||
        currentElement.type === ConstantsTool.RESOURCE_TEXT ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
        currentElement.idType?.includes("ws_title") ||
        currentElement.idType?.includes("ws_description") ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_MATH ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE
    );
};
export function moveElementFromIndexToIndex(
    array: any[],
    from: number,
    to: number
) {
    return array.splice(to, 0, ...array.splice(from, 1)), array;
}

export function getBrowser() {
    var ua = navigator.userAgent,
        tem,
        M =
            ua.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
            return { name: "Opera", version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

    if ((tem = ua.match(/Version\/(\d+\.\d+)/)) != null) {
        M.splice(1, 1, tem[1]);
    }

    return {
        name: M[0],
        version: M[1],
    };
}
export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const getTypeFromOldResourceItem = (
    resourceItem: IResourceItemNew,
    sendGa: boolean = true
) => {
    let type = -1;
    switch (resourceItem.type) {
        case Config.RESOURCE_ELEMENT:
        case Config.RESOURCE_TEMPLATE:
        case Config.RESOURCE_UPLOAD:
            // case
            type = ConstantsTool.RESOURCE_IMAGE;
            break;
        case Config.RESOURCE_TEXT:
            if (resourceItem.moduleId) {
                type = ConstantsTool.TYPE_RESOURCE_HANDWRITING;
                break;
            }
        case Config.RESOURCE_TEXT_TEMPLATE:
            type = ConstantsTool.RESOURCE_TEXT;
            break;
        case Config.RESOURCE_BACKGROUND:
        case Config.RESOURCE_FRAMES:
            type = ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE;
            break;
        case Config.RESOURCE_LINE:
            type = ConstantsTool.TYPE_RESOURCE_LINE;
            break;
        // case Config.RESOURCE_FRAMES:
        //     type = ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES;
        //     break;
        default:
            type = resourceItem.type;
    }
    return type;
};
export const getStyleText = (
    item: IResourceItemNew,
    checkLineThroughText?: boolean
) => {
    return {
        ...getTextEffectStyle(item),
        // transform: `rotate(${item.rotate})`,
        fontSize: item.textAttribute.fontSize,
        fontFamily: item.textAttribute.fontFamily,
        fontStyle: item.textAttribute.isItalic ? "italic" : "normal",
        fontWeight: item.textAttribute.isBold ? "bold" : "normal",
        textAlign: item.textAttribute.align ?? "justify",
        color: getTextEffectStyle(item).fill
            ? getTextEffectStyle(item).fill
            : item.textAttribute.fontColor
            ? item.textAttribute.fontColor
            : "#212121",
        textDecoration: checkLineThroughText ? "line-through" : "normal",
        wordBreak: "break-all",
    };
};
export const convertPageWsFromOldResourceItem = (
    resourceItems: IResourceItem[]
) => {
    let mappingIndexResource = {};
    if (resourceItems?.length) {
        resourceItems?.forEach((el) => {
            let key = el.page.toString();
            if (mappingIndexResource[key]) {
                mappingIndexResource[key].push(el);
            } else {
                mappingIndexResource[key] = [el];
            }
        });
    }
    let listPageIndex = Object.keys(mappingIndexResource);
    let newPages: IPageWorksheetNew[] = [];
    for (let i = 0; i < listPageIndex.length; i++) {
        newPages.push(
            new PageWorksheetNew({
                id:
                    new Date().getTime().toString() +
                    "_" +
                    Math.floor(Math.random() * 99999999).toString(),
                width: ConstantsTool.WIDTH_A4,
                height: ConstantsTool.HEIGHT_A4,
                resourceItems: mappingIndexResource[listPageIndex[i]].map(
                    (el) => {
                        let type = getTypeFromOldResourceItem(el, false);
                        let size: any;
                        if (
                            type === ConstantsTool.RESOURCE_TEXT ||
                            type === ConstantsTool.RESOURCE_TEXT_MODULE
                        ) {
                            size = getTextWidthHeightTool({
                                includePadding: true,
                                textValue: el.svgContent,
                                fontFamily: converOldFont(el.fontFamily),
                                fontSize: el.fontSize,
                                textAlign: el.align,
                                maxWidth: el.width,
                            });
                            if (size.width > el.width) {
                                el.width = size.width;
                            }
                            if (size.height > el.height) {
                                el.height = size.height;
                            }
                        }

                        return new ResourceItemNew({
                            ...el,
                            type: getTypeFromOldResourceItem(el, false),
                            rotate: el.rotation,
                            pageIndex: el.page,
                            textAttribute: {
                                content: el.svgContent,
                                align: el.align,
                                fontFamily: converOldFont(el.fontFamily),
                                fontSize: el.fontSize,
                                color: el.fontColor,
                                autoFill: el.autoFillOn,
                            },
                            imageAttribute: {
                                url: el.url,
                            },
                            isShow:
                                el.type != Config.RESOURCE_DEFAULT_BACKGROUND,
                        });
                    }
                ),
            })
        );
    }
    return newPages;
};
export const isErrorImageSvg = (resourceItem: IResourceItemNew) => {
    return resourceItem.imageAttribute.url.endsWith("..svg");
};
export const isImageSvg = (
    resourceItem: IResourceItemNew | IResourceItemNew
) => {
    return (
        resourceItem?.imageAttribute?.url?.endsWith("..svg") ||
        resourceItem?.imageAttribute?.url?.endsWith(".svg") ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE ||
        resourceItem.type === ConstantsTool.TYPE_WORD_BANK ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
    );
};
export const resourceCanEditColorOnTop = (
    resourceItem: IResourceItemNew | IResourceItemNew
) => {
    return (
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_MODULE ||
        resourceItem.type === ConstantsTool.TYPE_WORD_DIRECTION ||
        resourceItem.type === ConstantsTool.TYPE_WORD_LIST ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST ||
        resourceItem.type === ConstantsTool.TYPE_WORD_BANK ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
    );
};
export const listActiveTabUsingSearch = (activeTab: number) => {
    return (
        activeTab === Config.RESOURCE_ELEMENT ||
        activeTab === Config.RESOURCE_BACKGROUND ||
        activeTab === Config.RESOURCE_TEMPLATE
    );
};
export const getGapBetweenItem = (fontSize: number) => {
    return fontSize * 0.42;
};
export const listResourceCanDuplicate = (currentItem: IResourceItemNew) => {
    return (
        currentItem.type !== ConstantsTool.RESOURCE_BACKGROUND &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
        currentItem.type !== ConstantsTool.RESOURCE_TEXT_MODULE &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_MODULE &&
        currentItem.type !== ConstantsTool.TYPE_STUDENT_INFO &&
        currentItem.type !== ConstantsTool.TYPE_WORD_DIRECTION &&
        currentItem.type !== ConstantsTool.TYPE_LOGO &&
        currentItem.type !== ConstantsTool.TYPE_WORD_LIST &&
        currentItem.type !== ConstantsTool.TYPE_WORD_BANK &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_HANDWRITING &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_NAME_TRACING &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES &&
        currentItem.type !== ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES &&
        !currentItem.idType?.includes("fill_the_blank") &&
        !currentItem.idType?.includes("ws_generator") &&
        !currentItem.isGroup &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK &&
        currentItem.type !== ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST
    );
};
export const listResourceNoAction = (currentItem: IResourceItemNew) => {
    return currentItem?.type === ConstantsTool.RESOURCE_BACKGROUND;
};

export const getWidthHeightTemplate = (param: {
    worksheet: IWorksheet;
    resourceItems: any[];
}) => {
    let { worksheet, resourceItems } = param;
    let width = 0;
    let height = 0;
    let isNeeedResize = true;
    let pageIndex;
    try {
        pageIndex = resourceItems[0].pageIndex;
        if (!pageIndex) {
            // check for old data
            pageIndex = resourceItems[0].page;
        }
    } catch (e) {
        pageIndex = 0; // default padeIndex  = 0
    }
    let widthActionWsTemplate = worksheet?.game?.screenSize?.width;
    let heightActionWsTemplate = worksheet?.game?.screenSize?.height;
    if (worksheet?.game?.screenSizes?.length > pageIndex) {
        widthActionWsTemplate = worksheet?.game?.screenSizes[pageIndex].width;
        heightActionWsTemplate = worksheet?.game?.screenSizes[pageIndex].height;
        if (
            widthActionWsTemplate <= ConstantsTool.INIT_WIDTH_A4 ||
            heightActionWsTemplate <= ConstantsTool.INIT_HEIGHT_A4
        ) {
            isNeeedResize = false;
        }
    }
    if (widthActionWsTemplate && heightActionWsTemplate) {
        width = widthActionWsTemplate;
        height = heightActionWsTemplate;
    } else {
        resourceItems.forEach((e) => {
            if (
                e.width <= ConstantsTool.INIT_HEIGHT_A4 &&
                e.height <= ConstantsTool.INIT_HEIGHT_A4
            ) {
                width = width < e.width ? e.width : width;
                height = height < e.height ? e.height : height;
            }
        });
        if (width < height) {
            if (width > ConstantsTool.INIT_WIDTH_A4) {
                width = ConstantsTool.INIT_WIDTH_A4;
            }
        }
    }
    let ratioX = 1;
    let ratioY = 1;
    let newRatio = 1;
    let ratioFont = 1;
    if (isNeeedResize) {
        ratioFont = newRatio = CreateWSConfig.WIDTH / width;
        // if (ratioFont < 0.3) {
        //     ratioFont = 1; // limmit ratioFont
        // }
        // if (width <= height) {
        //     //horizontalPage
        //     let ratioWidth = CreateWSConfig.WIDTH / width;
        //     let ratioHeight = CreateWSConfig.HEIGHT / height;
        //     newRatio = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;
        // } else {
        //     //verticalPage
        //     let ratioWidth = CreateWSConfig.WIDTH / width;
        //     let ratioHeight = CreateWSConfig.HEIGHT / height / 2;
        //     newRatio = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;
        //     ratioFont = newRatio;
        // }
        ratioX = ratioY = newRatio;
        width = width * ratioX;
        height = height * ratioY;
    }
    return {
        width,
        height,
        ratioX,
        ratioY,
        ratioFont,
    };
};

const setWidthHeightTemplate = ({
    worksheet,
    resourceItems,
    orientation,
}: {
    worksheet: IWorksheet;
    resourceItems: IResourceItemNew[];
    orientation?: number;
}) => {
    let { width, height, ratioX, ratioY, ratioFont } = getWidthHeightTemplate({
        worksheet,
        resourceItems,
    });
    if (isNaN(orientation)) {
        ConstantsTool.changeOrientation(
            ConstantsTool.ORIENTATION.PORTRAIT,
            width,
            height
        );
    } else {
        ConstantsTool.changeOrientation(orientation);
    }

    return {
        ratioX,
        ratioY,
        ratioFont,
    };
};

export const resizeElementToSizeA4 = ({ worksheet, resourceItems }) => {
    if (!resourceItems) {
        return;
    }
    let { ratioX, ratioY, ratioFont } = setWidthHeightTemplate({
        worksheet,
        resourceItems,
    });

    resourceItems = resourceItems.map((el: IResourceItem) => {
        let e: any = new ResourceItem(el);
        e.x *= ratioX;
        e.y *= ratioY;
        e.width *= ratioX;
        e.height *= ratioY;
        // e.colors = getColorsFromXML(e.svgContent);
        if (e.transformData) {
            if (e.svgContent) {
                e.svgContent = setSvgSizeFromContent(
                    e.transformData?.width ?? e.width,
                    e.transformData?.height ?? e.height,
                    e.svgContent
                );
            } else {
                e.width = e.transformData.width * ratioX;
                e.height = e.transformData.height * ratioY;
                e.transformData = null;
            }
        }
        if (ratioFont < 1) {
            e.fontSize *= ratioFont;
        }
        return e;
    });
    return resourceItems;
};

export const setSvgSizeFromContent = (
    width: number,
    height: number,
    content: string
) => {
    try {
        const parser = new DOMParser();
        const xml = parser.parseFromString(content, "text/xml");
        const svg = xml.querySelector("svg");
        svg.setAttribute("width", width + "");
        svg.setAttribute("height", height + "");
        return svg.outerHTML;
    } catch (error) {
        return content;
    }
};
export const convertListColorToObjectColor = (listColor: string[]) => {
    let objectColor = {};
    listColor.map((color) => {
        objectColor[color] = color;
    });
    if (Object.keys(objectColor).length === 0) {
        objectColor["#000000"] = "#000000";
    }
    return objectColor;
};
export const listOpenWsInfo = (resourceItem: IResourceItemNew) => {
    return (
        // resourceItem.idType?.includes("ws_title_") ||
        resourceItem.idType?.includes("ws_description_") ||
        resourceItem.idType?.includes("ws_generator") ||
        resourceItem.type === ConstantsTool.TYPE_STUDENT_INFO
    );
};
export const checkWordIsSelected = (
    resourceItem: IResourceItemNew,
    wordIndex: number
) => {
    return resourceItem?.question?.answers?.some(
        (answer) => answer.indexWord === wordIndex
    );
};
export async function loadFonts(fontInfo: IFont) {
    let allFonts = Array.from(document.fonts);
    if (!allFonts.find((e) => e.family === fontInfo.name)) {
        if (fontInfo.url.includes("worksheetzone//")) {
            fontInfo.url = fontInfo.url.replace(
                "worksheetzone//",
                "worksheetzone/"
            );
        }
        const font = new FontFace(
            fontInfo.name,
            `url(${fontInfo.url})`
        );
        const style = document.createElement("style");
        style.textContent = `
            @font-face {
                font-family: '${fontInfo.name}';
                src: url(${fontInfo.url});
            }
        `;
        document.head.appendChild(style);

        // wait for font to be loaded
        await font.load();
        // // add font to document
        // document.fonts.add(font);
        // // enable font with CSS class
        // document.body.classList.add("fonts-loaded");
    }
}
export const getHeightOfWordBank = (
    wordBanks: string[],
    wordBankResource?: IResourceItemNew
) => {
    let heightContentWordBank = 0;
    let maxWidthContentWordBank =
        wordBankResource?.width ?? ConstantsTool.MAX_WIDTH_TEXT_FILL_IN_BLANK;
    let text = "";
    wordBanks.forEach((wordBank) => {
        text += wordBank + " ";
    });
    const divElement = document.createElement("div");
    divElement.style.width =
        maxWidthContentWordBank -
        ConstantsTool.PADDING_WORD_BANK_HORIZONTAL * 2 +
        "px";
    divElement.style.height = "fit-content";
    divElement.style.wordSpacing = "8px";
    divElement.style.lineHeight = "1.6";
    divElement.style.opacity = "0";
    divElement.style.fontSize = "24px";
    divElement.textContent = text;
    document.body.appendChild(divElement);
    const divHeight = divElement.offsetHeight;
    heightContentWordBank = divHeight;
    divElement.remove();
    return heightContentWordBank + ConstantsTool.PADDING_WORD_BANK_VERTICAL * 2;
};
export const isTextFillInBlank = (
    resourceItem: IResourceItemNew,
    activityId: string
) => {
    return (
        resourceItem.activityId === activityId &&
        resourceItem.type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK
    );
};
export const handleCalculateWord = ({
    diffHeight,
    resourceFillTheBlank,
    listStyle,
    action,
    lineHeight,
    resourceUpdateId,
    heightGap,
    originY,
}: {
    diffHeight: number;
    resourceFillTheBlank: IResourceItemNew[];
    listStyle: number;
    action: string;
    resourceUpdateId?: string;
    heightGap: number;
    lineHeight?: number;
    originY?: number;
}) => {
    let listTextQuestion = [...resourceFillTheBlank];
    listTextQuestion.sort((a, b) => {
        if (a.pageIndex !== b.pageIndex) {
            return a.pageIndex - b.pageIndex;
        }
        return a.y - b.y;
    });
    let checkIndexResourceRemove = false;
    // const maxYWordBank = originY ? originY + ConstantsTool.PADDING_BORDER : 0;
    if (listTextQuestion.length) {
        // let startY = listTextQuestion[0].y;
        // let pageIndex = 0;
        // let dy = 0;
        listTextQuestion = listTextQuestion.map(
            (el: IResourceItemNew, index: number) => {
                let newHeight = el.height;
                let newWidth = el.width;
                let indexQuestion = checkIndexResourceRemove
                    ? el.question.indexQuestion - 1
                    : el.question.indexQuestion;
                let newTextAttribute = {
                    ...el.textAttribute,
                    content: getTextContentWithStyle(
                        indexQuestion,
                        el.question.question,
                        listStyle
                    ),
                };
                if (
                    (resourceUpdateId === el.id &&
                        action ===
                            CreateWSConfig.LIST_ACTION
                                .ACTION_CHANGE_LINE_HEIGHT) ||
                    action ===
                        CreateWSConfig.LIST_ACTION.ACTION_CHANGE_LIST_STYLE
                ) {
                    let size = getSizeBoxText({
                        textAttribute: newTextAttribute,
                        width: el.width,
                        type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
                        listStyle,
                    });
                    newWidth = size.width;
                    newHeight = size.height;
                    // if (
                    //     action ===
                    //     CreateWSConfig.LIST_ACTION.ACTION_CHANGE_LINE_HEIGHT
                    // ) {
                    //     newTextAttribute.lineHeight = lineHeight;
                    // }
                }
                // if (
                //     index === 0 &&
                //     action !== CreateWSConfig.LIST_ACTION.ACTION_EDIT_TEXT
                // ) {
                //     // startY += diffHeight;
                //     if (originY && startY < maxYWordBank) {
                //         dy = maxYWordBank - startY;
                //         startY = maxYWordBank;
                //     }
                // }

                // if (resourceUpdateId) {
                //     if (startY + newHeight > ConstantsTool.MAX_Y_BORDER) {
                //         pageIndex += 1;
                //         startY = ConstantsTool.BORDER_Y + 20;
                //     }

                //     if (
                //         resourceUpdateId === el.id &&
                //         action ===
                //             CreateWSConfig.LIST_ACTION
                //                 .ACTION_ADD_REMOVE_QUESTION
                //     ) {
                //         //remove resource and set y in the index + 1 = y in the index - (diffWordBankHeight)
                //         checkIndexResourceRemove = true;
                //         return null;
                //     }
                // } else {
                //     if (startY + el.height > ConstantsTool.MAX_Y_BORDER) {
                //         pageIndex += 1;
                //         startY = ConstantsTool.BORDER_Y + 20;
                //     }
                // }
                // let y = startY;
                // if (action === null) {
                //     y = el.y + dy;
                // }
                let newEl = new ResourceItemNew({
                    ...el,
                    width: newWidth,
                    // y: y,
                    height: newHeight,
                    // pageIndex: pageIndex,
                    textAttribute: {
                        ...newTextAttribute,
                    },
                    question: {
                        ...el.question,
                        indexQuestion,
                    },
                });

                // if (
                //     resourceUpdateId === el.id &&
                //     action === CreateWSConfig.LIST_ACTION.ACTION_EDIT_TEXT
                // ) {
                //     startY += newHeight + heightGap + diffHeight;
                // } else {
                //     startY += newHeight + heightGap;
                // }

                return newEl;
            }
        );
    }
    return convertPageWs({
        newResourceItems: listTextQuestion.filter((el) => el),
    });
};
export const convertWsCreatorToWorksheet = (
    wsCreator: IWorkSheetCreator,
    activity: IActivity,
    wordSearchCreator: IPuzzle
) => {
    return new Puzzle({
        ...wordSearchCreator,
        ...wsCreator,
        ...activity,
        puzzleShape: activity.shape,
        puzzleSize: activity.size,
    });
    //
};
const searchTypeActivity = (searchParam) => {
    if (searchParam.includes("word-search-maker")) {
        return Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE;
    }
    if (searchParam.includes("handwriting-worksheet-generator")) {
        return Config.ACTIVITY_TYPE.HANDWRITING.TYPE;
    }
    if (searchParam.includes("fill-in-the-blank-generator")) {
        return Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE;
    }
    if (searchParam.includes("name-tracing-generator")) {
        return Config.ACTIVITY_TYPE.NAME_TRACING.TYPE;
    }
    if (searchParam.includes("crossword-puzzle-maker")) {
        return Config.ACTIVITY_TYPE.CROSSWORD.TYPE;
    }
    if (searchParam.includes(Routes.ADDITION.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.ADDITION.TYPE;
    }
    if (searchParam.includes(Routes.SUBTRACTION.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.SUBTRACTION.TYPE;
    }
    if (searchParam.includes(Routes.MULTIPLICATION.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE;
    }
    if (searchParam.includes(Routes.DIVISION.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.DIVISION.TYPE;
    }
    if (searchParam.includes(Routes.WORD_SCRAMBLE.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE;
    }
    if (searchParam.includes(Routes.SENTENCE_SCRAMBLE.replace("/", ""))) {
        return Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE;
    }
    return Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;
};
export const getInitTypeActivity = () => {
    return (
        process.env.REACT_APP_INIT_TYPE ??
        Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE
    );
};
export const getInitTypeFromUrl = () => {
    let pathName = window.location.pathname;

    if (pathName.includes(Routes.WORD_SEARCH_MAKER)) {
        return Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE;
    }
    if (pathName.includes(Routes.HANDWRITING)) {
        return Config.ACTIVITY_TYPE.HANDWRITING.TYPE;
    }
    if (pathName.includes(Routes.FILL_IN_BLANK)) {
        return Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE;
    }
    if (pathName.includes(Routes.NAME_TRACING)) {
        return Config.ACTIVITY_TYPE.NAME_TRACING.TYPE;
    }
    if (pathName.includes(Routes.MULTIPLE_CHOICES)) {
        return Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE;
    }
    if (pathName.includes(Routes.ADDITION)) {
        return Config.ACTIVITY_TYPE.ADDITION.TYPE;
    }
    if (pathName.includes(Routes.SUBTRACTION)) {
        return Config.ACTIVITY_TYPE.SUBTRACTION.TYPE;
    }
    if (pathName.includes(Routes.MULTIPLICATION)) {
        return Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE;
    }
    if (pathName.includes(Routes.DIVISION)) {
        return Config.ACTIVITY_TYPE.DIVISION.TYPE;
    }
    if (pathName.includes(Routes.WORD_SCRAMBLE)) {
        return Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE;
    }
    if (pathName.includes(Routes.SENTENCE_SCRAMBLE)) {
        return Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE;
    }
    if (pathName.includes(Routes.CUSTOMIZE_WORKSHEET)) {
        let searchParam = window.location.search;
        return searchTypeActivity(searchParam);
    }
    if (pathName.includes(Routes.CROSSWORD)) {
        return Config.ACTIVITY_TYPE.CROSSWORD.TYPE;
    }
    if (pathName === "/" || pathName.includes(Routes.WORKSHEET_MAKER)) {
        return Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;
    }
    // throw new Error("getInitTypeFromUrl: Not match any pathName");
};
export const getTextContentWithStyle = (
    indexQuestion: number,
    content: string,
    listStyle: number
) => {
    let newContent = content[0].toUpperCase() + content.slice(1);
    switch (listStyle) {
        case ListStyle.INDEX:
            return indexQuestion.toString() + ". " + newContent;
        case ListStyle.BULLET_POINT:
            return "- " + newContent;
        default:
            return newContent;
    }
};
export const shuffleArray = (array) => {
    const shuffledArray = [...array]; // Create a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
        ]; // Swap elements
    }
    return shuffledArray;
};
export const getListUrlFillTheBlank = () => {
    return (
        window.location.href.includes("fill-in-blank") ||
        window.location.href.includes("fill-in-the-blank-generator")
    );
};

export const getConfigByActivity = (activity: string = "") => {
    const activityAttribute = {
        [Config.ACTIVITY_TYPE.HANDWRITING.TYPE]: {
            attribute: "handwritingAttribute",
            resourceType: ConstantsTool.TYPE_RESOURCE_HANDWRITING,
            width: ConstantsTool.HANDWRITING_WIDTH,
            x: ConstantsTool.HANDWRITING_X,
            y_first: ConstantsTool.HANDWRITING_FIRST_PAGE_Y,
            y_other: ConstantsTool.HANDWRITING_OTHER_PAGE_Y,
        },
        [Config.ACTIVITY_TYPE.NAME_TRACING.TYPE]: {
            attribute: "nameTracingAttribute",
            resourceType: ConstantsTool.TYPE_RESOURCE_NAME_TRACING,
            width: ConstantsTool.NAME_TRACING_WIDTH,
            x: ConstantsTool.NAME_TRACING_X,
            y_first: ConstantsTool.NAME_TRACING_FIRST_PAGE_Y,
            y_other: ConstantsTool.NAME_TRACING_OTHER_PAGE_Y,
        },
    };
    return (
        activityAttribute[activity] ??
        activityAttribute[Config.ACTIVITY_TYPE.HANDWRITING.TYPE]
    );
};
export function removeOneInstanceFromArray(arr, elementsToRemove) {
    const elementCount = {};

    for (const element of elementsToRemove) {
        elementCount[element] = (elementCount[element] || 0) + 1;
    }

    const result = [];

    for (const element of arr) {
        if (elementCount[element] && elementCount[element] > 0) {
            elementCount[element]--;
        } else {
            result.push(element);
        }
    }

    return result;
}

export const isResourceBackground = (resourceType: number) => {
    return (
        resourceType === ConstantsTool.RESOURCE_BACKGROUND ||
        resourceType === ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
    );
};

export const getTemplateTypebaseUrl = () => {
    let pathName = window.location.pathname;
    if (pathName.includes(Routes.WORD_SEARCH_MAKER)) {
        return Config.USER_CREATED_TOOL_V2_WORD_SEARCH;
    }
    if (pathName.includes(Routes.HANDWRITING)) {
        return Config.USER_CREATED_TOOL_V2_HAND_WRITING;
    }
    if (pathName.includes(Routes.FILL_IN_BLANK)) {
        return Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK;
    }
    if (pathName.includes(Routes.NAME_TRACING)) {
        return Config.USER_CREATED_TOOL_V2_NAME_TRACING;
    }
    if (pathName.includes(Routes.CROSSWORD)) {
        return Config.USER_CREATED_TOOL_V2_CROSSWORD;
    }
    if (pathName.includes(Routes.ADDITION)) {
        return Config.USER_CREATED_TOOL_V2_ADDITION;
    }
    if (pathName.includes(Routes.SUBTRACTION)) {
        return Config.USER_CREATED_TOOL_V2_SUBTRACTION;
    }
    if (pathName.includes(Routes.MULTIPLICATION)) {
        return Config.USER_CREATED_TOOL_V2_MULTIPLICATION;
    }
    if (pathName.includes(Routes.DIVISION)) {
        return Config.USER_CREATED_TOOL_V2_DIVISION;
    }
    if (pathName.includes(Routes.CUSTOMIZE_WORKSHEET)) {
        return null;
    }
    if (pathName.includes(Routes.WORKSHEET_MAKER)) {
        let typeInit = Cookies.get(Constants.ACTIVITY_ACTIVE_TOOLV2);
        return convertCurrentActitvityToTemplateType(typeInit);
    }
};

export const getResourceItemSize = (
    resourceItem: IResourceItemNew,
    width: number
): { width: number; height: number } | null => {
    switch (resourceItem.type) {
        case ConstantsTool.TYPE_RESOURCE_SHAPE:
            width = 120;
            return {
                width: width,
                height: (width * resourceItem.height) / resourceItem.width,
            };
        case ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE:
            width = 120;
            return {
                width: width,
                height: (width * resourceItem.height) / resourceItem.width,
            };
        case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
            return { width: resourceItem.width, height: resourceItem.height };
        case ConstantsTool.TYPE_RESOURCE_NORMAL_LINE:
            return { width: resourceItem.width, height: resourceItem.height };
        case ConstantsTool.TYPE_RESOURCE_TABLE:
            return { width: resourceItem.width, height: resourceItem.height };
        default:
            return null;
    }
};

export const canResizeCorner = (currentElement: IResourceItemNew) => {
    return (
        currentElement.type !== ConstantsTool.RESOURCE_TEXT &&
        currentElement.type !== ConstantsTool.RESOURCE_TEXT_MODULE &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_NORMAL_LINE &&
        currentElement.type !==
            ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK &&
        currentElement.type !== ConstantsTool.TYPE_TEXT_FILL_THE_BLANK &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_MATH_FORMULA
    );
};
export const convertCurrentActitvityToTemplateType = (
    currentActivityType: string
) => {
    switch (currentActivityType) {
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            return Config.USER_CREATED_TOOL_V2_WORD_SEARCH;
        case Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE:
            return Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK;
        case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
            return Config.USER_CREATED_TOOL_V2_HAND_WRITING;
        case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
            return Config.USER_CREATED_TOOL_V2_NAME_TRACING;
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            return Config.USER_CREATED_TOOL_V2_CROSSWORD;
        case Config.ACTIVITY_TYPE.ADDITION.TYPE:
            return Config.USER_CREATED_TOOL_V2_ADDITION;
        case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
            return Config.USER_CREATED_TOOL_V2_SUBTRACTION;
        case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
            return Config.USER_CREATED_TOOL_V2_MULTIPLICATION;
        case Config.ACTIVITY_TYPE.DIVISION.TYPE:
            return Config.USER_CREATED_TOOL_V2_DIVISION;
        case Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE:
            return Config.USER_CREATED_TOOL_V2_WORD_SCRAMBLE;
        case Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE:
            return Config.USER_CREATED_TOOL_V2_SENTENCE_SCRAMBLE;
        default:
            return null;
    }
};
export const isResourceBorder = (resourceType: number) => {
    return (
        resourceType === ConstantsTool.TYPE_RESOURCE_BORDER ||
        resourceType === ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
    );
};
export const convertWhitespaceToHtml = (text: string): string => {
    return text.replace(/ /g, "&nbsp;");
};

export const getPositionResource = (
    width: number,
    height: number,
    resourceItems: IResourceItemNew[],
    size: { width: number; height: number },
    position?: { x: number; y: number }
) => {
    let x = position?.x ?? size.width / 2 - width / 2,
        y = position?.y ?? size.height / 2 - height / 2;
    resourceItems.forEach((element) => {
        // if (x,y) intersextion with (element.x, element.y), then translation to below position of element
        if (
            (element.x <= x && x <= element.x + element.width) ||
            (element.x < x + width && x + width < element.x + element.width) ||
            (element.y <= y && y <= element.y + element.height) ||
            (element.y < y + height && y + height < element.y + element.height)
        ) {
            x = element.x + element.width + 10;
            y = element.y + element.height + 10;
        }
    });
    if (x + width > size.width) {
        x = size.width - width;
    }
    if (y + height > size.height) {
        y = size.height - height;
    }

    return { x: x, y: y };
};
export const isResourceInteractive = (type: Number) => {
    return (
        type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
        type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE
    );
};

export const resourceCanEditShape = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    const funcCheck = (currentElement: IResourceItemNew) => {
        return (
            currentElement.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
            currentElement.type ===
                ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
            currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE ||
            currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
        );
    };
    return funcCheck(currentElement);
};

export const isResourceNotClick = (resourceItem: IResourceItemNew) => {
    return (
        resourceItem.type === ConstantsTool.TYPE_LOGO ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_BORDER
    );
};

export const makeImageAttribute = (
    resourceItem: IResourceItemNew,
    imageAttribute: any
): IImageAttribute => {
    let changeColor = imageAttribute.changeColor;
    if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_SHAPE) {
        changeColor = { color: "#E5E7EB" };
    }
    if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE) {
        changeColor = { color: "#E5E7EB", colorBorder: "#000000" };
    }
    if (
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
    ) {
        changeColor = { color: "#212121" };
    }

    return { ...imageAttribute, changeColor };
};

export const resourceCanEditTransparency = (
    resourceItem: IResourceItemNew,
    resourceItems?: IResourceItemNew[]
) => {
    const types = [
        ConstantsTool.RESOURCE_IMAGE,
        ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE,
        ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES,
        ConstantsTool.RESOURCE_TEXT,
        ConstantsTool.RESOURCE_TEXT_MODULE,
        ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE,
        ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE,
        ConstantsTool.TYPE_RESOURCE_NORMAL_LINE,
    ];
    if (resourceItem.resourceIds?.length && resourceItems?.length) {
        let count = 0;
        for (let i in resourceItems) {
            if (types.includes(resourceItems[i].type)) {
                count++;
            }
        }
        return count > 0;
    }
    if (types.includes(resourceItem.type)) {
        return true;
    }
    return false;
};

export const disapleInteractiveElement = (pages: IPageWorksheetNew[]) => {
    let disaple = false;
    pages.forEach((page) => {
        page.resourceItems.forEach((e) => {
            if (
                e.type === ConstantsTool.TYPE_RESOURCE_MODULE ||
                e.type === ConstantsTool.TYPE_RESOURCE_HANDWRITING ||
                e.type === ConstantsTool.TYPE_RESOURCE_NAME_TRACING ||
                e.type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
                e.type === ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES
            ) {
                disaple = true;
            }
        });
    });
    return disaple;
};

export const getActivityConfigCrossword = (
    activities: IActivity[],
    state: RootState
) => {
    const crosswordType = Config.ACTIVITY_TYPE.CROSSWORD.TYPE;
    const crosswordActivity = activities.find(
        (activity) => activity.type === crosswordType
    );
    if (crosswordActivity) {
        const { grid, layout, showAnswerKey, showWordBank, words } =
            state.crosswordState;
        crosswordActivity.showAnswerKey = showAnswerKey
            ? Config.SHOW_VALUE
            : Config.HIDE_VALUE;
        crosswordActivity.showWordBank = showWordBank
            ? Config.SHOW_VALUE
            : Config.HIDE_VALUE;
        crosswordActivity.grid = JSON.stringify(grid);
        crosswordActivity.layout = layout;
        crosswordActivity.questions = words.map((word) => {
            return new QuestionActivity({
                id: generateIdFromDateTime(),
                question: word.value,
                indexQuestion: word.index,
                crosswordAttribute: word,
                type: crosswordType,
            });
        });
    }
};

export const getConfigCrossword = (activitiy: IActivity): ICrossword => {
    const { showAnswerKey, showWordBank, grid, layout, questions } = activitiy;

    const words = questions.map(
        (question) =>
            new Word({
                value: question.question,
                ...question.crosswordAttribute,
            })
    );

    const result = {
        showAnswerKey: showAnswerKey === Config.SHOW_VALUE,
        showWordBank: showWordBank === Config.SHOW_VALUE,
        layout,
        grid: grid ? JSON.parse(grid) : [],
        words,
    };

    return result;
};

export const convertQuestionFromOldData = (
    resourceItems: IResourceItemNew[],
    modules: any
) => {
    let curentModule: any;
    if (resourceItems?.length > 0 && modules?.length > 0) {
        let pageIndex = resourceItems[0].pageIndex;
        curentModule = modules[pageIndex];
    }
    let questionActivities = resourceItems
        .filter((e) => e.type === ConstantsTool.TYPE_RESOURCE_HANDWRITING)
        .map((ele: IResourceItemNew) => {
            return new QuestionActivity({
                id: generateIdFromDateTime(),
                question: ele.textAttribute.content,
                handwritingAttribute: {
                    autoFill: ele.textAttribute.autoFill,
                    color: ele.textAttribute.color,
                    lineSpacing: convertLineSpacing(ele.height),
                    numberOfLines: 1,
                    fontStyle: 1,
                    textStyle: converOldTextStyle(curentModule?.font),
                    fontFamily: "Quicksand",
                    resourceItemIds: [ele.id],
                    fontColor: ele.textAttribute.fontColor,
                },
                type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
            });
        });
    return questionActivities;
};

const converOldTextStyle = (oldFont: string) => {
    let newFont = ConstantsTool.TEXT_STYLE.DASHED;
    switch (oldFont) {
        case "filled":
            newFont = ConstantsTool.TEXT_STYLE.REGULAR;
            break;
        case "ellipsis":
            newFont = ConstantsTool.TEXT_STYLE.DASHED;
            break;
        default:
            break;
    }
    return newFont;
};

const converOldFont = (oldFont: string) => {
    let newFont = oldFont;
    switch (oldFont) {
        case "TeachingPrintDotted":
            newFont = "Quicksand Dashed";
            break;
        case "Josefin Sans":
            newFont = "Quicksand Regular";
            break;
        default:
            break;
    }
    return newFont;
};

const convertLineSpacing = (height: number) => {
    let allSpacing = Object.values(ConstantsTool.LINE_SPACING);
    let indexMin = allSpacing.length - 1;
    let min = allSpacing[indexMin];
    for (let i = 0; i < allSpacing.length; i++) {
        let sub = allSpacing[i] - height;
        if (sub > 0 && sub < min) {
            min = sub;
            indexMin = i;
        }
    }
    return indexMin + 1;
};

export const eventAddResourceElement = (type: number) => {
    let event = null;
    switch (type) {
        case ConstantsTool.TYPE_RESOURCE_SHAPE:
            event = EventTracking.ncw_interactive_element_text_box;
            break;
        case ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE:
            event = EventTracking.ncw_element_shape;
            break;
        case ConstantsTool.TYPE_RESOURCE_NORMAL_LINE:
            event = EventTracking.ncw_element_line;
            break;
        case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
            event = EventTracking.ncw_interactive_element_line;
            break;
        case ConstantsTool.TYPE_RESOURCE_TABLE:
            event = EventTracking.ncw_add_table;
            break;
    }
    sendEvent(event);
};

export const updateIndexInPageWorksheet = (pages: IPageWorksheetNew[]) => {
    pages.map((page, index) => {
        page.resourceItems.map((resourceItem) => {
            resourceItem.pageIndex = index;
        });
    });
};

export const updateObject = (object: any, objectUpdate: any) => {
    let attrValues = Object.keys(objectUpdate).map((key) => {
        return {
            attribute: key,
            value: objectUpdate[key],
        };
    });
    for (let i = 0; i < attrValues.length; i++) {
        let attrValue = attrValues[i];
        object[attrValue.attribute] = attrValue.value;
    }
};
export const handleDiffHeight = ({
    resourceItem,
    diffHeight,
    updatedResource,
    action,
    resourceFillTheBlank,
    dispatch,
    listStyle,
    heightGap,
    originY,
}: {
    resourceItem?: IResourceItemNew;
    diffHeight: number;
    updatedResource?: IResourceItemNew;
    action: string;
    resourceFillTheBlank: IResourceItemNew[];
    dispatch: any;
    listStyle: number;
    heightGap: number;
    originY?: number;
}) => {
    let newResourceFillTheBlank = [...resourceFillTheBlank];
    if (resourceItem && updatedResource) {
        let indexResourceUpdate = newResourceFillTheBlank.findIndex(
            (el) => el.id === resourceItem.id
        );
        newResourceFillTheBlank[indexResourceUpdate] = updatedResource;
    }
    dispatch(
        updateManyResourceByType({
            pagesWs: handleCalculateWord({
                diffHeight: diffHeight,
                resourceFillTheBlank: newResourceFillTheBlank,
                listStyle,
                action: action,
                heightGap,
                originY,
            }),
            type: ConstantsTool.TYPE_TEXT_FILL_THE_BLANK,
        })
    );
};
export const canOverflowElement = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        currentElement.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_TABLE ||
        currentElement.type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
        currentElement.type === ConstantsTool.RESOURCE_TEXT
    );
};

export const getResourceTypeForBackGround = (
    pagesWorksheet: IPageWorksheetNew[]
) => {
    let typeResources = [Config.RESOURCE_FRAMES];
    let numberPageWsPdf = 0;
    pagesWorksheet.forEach((page) => {
        page.resourceItems.forEach((resourceItem) => {
            if (resourceItem.idType?.includes("background_ws_pdf_")) {
                numberPageWsPdf++;
            }
        });
    });
    if (numberPageWsPdf != pagesWorksheet.length) {
        typeResources.push(Config.RESOURCE_BACKGROUND);
    }
    return typeResources;
};

export const delay = (ms: number) => {
    new Promise((resolve) => setTimeout(resolve, ms));
};

export const isIndividualTool = () => {
    let pathName = window.location.pathname;
    if (
        pathName.includes(Routes.WORD_SEARCH_MAKER) ||
        pathName.includes(Routes.HANDWRITING) ||
        pathName.includes(Routes.FILL_IN_BLANK) ||
        pathName.includes(Routes.NAME_TRACING) ||
        pathName.includes(Routes.CROSSWORD)
    ) {
        return true;
    }
    return false;
};

export const calculateListQuestionFITB = ({
    resourceFillTheBlank,
    heightGap,
    originY,
}: {
    resourceFillTheBlank: IResourceItemNew[];
    heightGap: number;
    originY?: number;
}) => {
    let listTextQuestion = [...resourceFillTheBlank];
    listTextQuestion.sort((a, b) => {
        if (a.pageIndex !== b.pageIndex) {
            return a.pageIndex - b.pageIndex;
        }
        return a.y - b.y;
    });
    if (listTextQuestion.length) {
        let startY = listTextQuestion[0].y;
        let pageIndex = 0;
        listTextQuestion = listTextQuestion.map(
            (el: IResourceItemNew, index: number) => {
                if (index === 0) {
                    if (
                        originY &&
                        (startY < originY + ConstantsTool.PADDING_BORDER ||
                            (el.pageIndex > 0 &&
                                startY + el.height <
                                    ConstantsTool.MAX_Y_BORDER))
                    ) {
                        startY = originY + ConstantsTool.PADDING_BORDER;
                    }
                }
                if (startY + el.height > ConstantsTool.MAX_Y_BORDER) {
                    pageIndex += 1;
                    startY = ConstantsTool.BORDER_Y + 20;
                }
                let newEl = new ResourceItemNew({
                    ...el,
                    y: startY,
                    pageIndex,
                });
                startY += el.height + heightGap;
                return newEl;
            }
        );
    }
    return convertPageWs({
        newResourceItems: listTextQuestion.filter((el) => el),
    });
};
export const getLocalStoreDataWhenClickSeeMoreBgInWeb = () => {
    let autoSearchTemplateStr = localStorage.getItem(
        Constants.AUTO_SEARCH_TEMPLATE
    );
    if (autoSearchTemplateStr) {
        let worksheetId = "";
        let pathName = window.location.pathname;

        if (pathName.includes(Routes.WORKSHEET_MAKER)) {
            try {
                let splitValues = window.location.hash.split("&");
                worksheetId = splitValues[1].split("=")[1];
            } catch (e) {}
        } else {
            const params = new URLSearchParams(window.location.search);
            worksheetId = params.get("worksheetId");
        }
        let autoSearchTemplateData = JSON.parse(autoSearchTemplateStr);
        if (
            autoSearchTemplateData?.worksheetId
            // && autoSearchTemplateData?.worksheetId===worksheetId
        ) {
            return autoSearchTemplateData;
        }
    }
    return null;
};

export const resourceCanChangeShape = (
    currentElement: IResourceItemNew,
    resourceItems: IResourceItemNew[]
) => {
    let check = resourceCanEditShape(currentElement);
    if (currentElement.type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
        for (let i in resourceItems) {
            if (resourceCanEditShape(resourceItems[i])) {
                check = true;
                break;
            }
        }
    }
    return check;
};
export const getPositionTopEnd = ({
    pagesWorksheet,
    type,
    height,
}: {
    pagesWorksheet: IPageWorksheetNew[];
    type?: number;
    height: number;
}) => {
    let resourceItems: IResourceItemNew[] = [];
    let resourceItemsType: IResourceItemNew[] = [];
    let resources = [];
    pagesWorksheet.forEach((el) => resources.push(...el.resourceItems));

    let maxPageIndex: number = 0;
    let maxPageIndexType: number = 0;
    resources.forEach((el) => {
        if (
            el.isShow &&
            el.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE &&
            el.type !== ConstantsTool.TYPE_RESOURCE_BORDER &&
            el.type !== ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES &&
            el.type !== ConstantsTool.TYPE_LOGO &&
            el.type !== ConstantsTool.RESOURCE_BACKGROUND
        ) {
            if (el.pageIndex > maxPageIndex) {
                maxPageIndex = el.pageIndex;
            }
            resourceItems.push(el);
        }
        if (el.type === type) {
            resourceItemsType.push(el);
            if (el.pageIndex > maxPageIndex) {
                maxPageIndexType = el.pageIndex;
            }
        }
    });

    resourceItems = resourceItems.filter(
        (resourceItem) => resourceItem.pageIndex === maxPageIndex
    );

    resourceItemsType = resourceItemsType.filter(
        (resourceItem) => resourceItem.pageIndex === maxPageIndex
    );

    let y = ConstantsTool.PADDING_BORDER;
    let pageIndex = 0;

    if (resourceItems.length) {
        let resource = resourceItems.reduce((pre, next) => {
            if (pre.y + pre.height > next.y + next.height) return pre;
            return next;
        }, resourceItems[0]);
        if (resourceItemsType.length) {
            let resourceType = resourceItemsType.reduce((pre, next) => {
                if (pre.y + pre.height > next.y + next.height) return pre;
                return next;
            }, resourceItems[0]);
            let maxY = resourceType.y + resourceType.height;
            if (
                (maxPageIndex === maxPageIndexType &&
                    maxY - resource.y > height &&
                    !resourceItems.find(
                        (e) => e.y > maxY && e.y + e.height < resource.y
                    )) ||
                (maxPageIndex > maxPageIndexType &&
                    maxY + height + ConstantsTool.PADDING_BORDER <
                        pagesWorksheet[maxPageIndexType].height)
            ) {
                y = resourceType.height + resourceType.y;
                pageIndex = resourceType.pageIndex;
                return { y, pageIndex };
            }
        }
        y = resource.height + resource.y;
        pageIndex = resource.pageIndex;
    }

    return { y, pageIndex };
};

export const resourceCanDragGroup = (currentElement: IResourceItemNew) => {
    return (
        isResourceBackground(currentElement.type) ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_BORDER
    );
};

export const resourceCanEditText = (type: number) => {
    return type === ConstantsTool.RESOURCE_TEXT;
};

export const displayEditPage = (type: string) => {
    return type === Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;
};

export const canEditSpacingText = (type: number) => {
    return (
        type !== ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE &&
        type !== ConstantsTool.TYPE_RESOURCE_SHAPE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
    );
};

export const canEditVerticalAlign = (type: number) => {
    return (
        type !== ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE &&
        type !== ConstantsTool.TYPE_RESOURCE_SHAPE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
    );
};

export const canResizeText = (type: number) => {
    return (
        type !== ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE &&
        type !== ConstantsTool.TYPE_RESOURCE_SHAPE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE &&
        type !== ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE
    );
};

export const canConfigAutoFontSize = (type: number) => {
    return (
        type === ConstantsTool.RESOURCE_TEXT_MODULE ||
        type === ConstantsTool.RESOURCE_TEXT ||
        type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA
    );
};

export const canConfigContainerStyles = (type: number) => {
    return (
        type === ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER
    );
};

export const makeWorkBank = ({
    activityId,
    wordBanks,
}: {
    activityId: string;
    wordBanks: string[];
}) => {
    let id = "word_bank" + new Date().getTime();
    let heightWordBank = getHeightOfWordBank(wordBanks);
    return new ResourceItemNew({
        id: id,
        type: Config.TYPE_WORD_BANK,
        idType: id,
        pageIndex: 0,
        x: 60,
        y: SizeA4.HEIGHT_A4 * 0.15,
        activityId: activityId,
        width: ConstantsTool.MAX_WIDTH_TEXT_FILL_IN_BLANK, // -2 border
        height: heightWordBank, // -2 border
        wordBanks: wordBanks,
        textAttribute: new TextAttribute({
            fontSize: 24,
            fontFamily: "Nunito",
        }),
        imageAttribute: new ImageAttribute({
            changeColor: {
                color: "#212121",
            },
        }),
    });
};

export const getSizeResourceText = (
    textAttribute: ITextAttribute,
    currentElement: IResourceItemNew,
    listStyle: ListStyle
) => {
    let size = {
        height: currentElement.height,
        width: currentElement.width,
    };
    if (canResizeText(currentElement.type))
        size = getSizeBoxText({
            textAttribute: textAttribute,
            width: currentElement.width,
            type: currentElement.type,
            listStyle,
        });
    return size;
};
export const isResourceLine = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        currentElement.type === ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_LINE
    );
};

export const isResourceShape = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        currentElement.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
        currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE
    );
};

export const getShapeAttribute = (
    resourceItems: IResourceItemNew[],
    type: "line" | "shape"
) => {
    let lines = resourceItems.filter((e) => isResourceLine(e));
    let shapes = resourceItems.filter((e) => isResourceShape(e));
    const isLine = type === "line";
    const array = isLine ? lines : shapes;

    let strokeWidth = isLine
        ? array[0].height
        : array[0].shapeAttribute.strokeWidth / 2;
    let strokeDasharray = array[0].shapeAttribute.strokeDasharray;
    let radius = array[0].shapeAttribute.radius;

    array.forEach((resourceItem) => {
        if (isLine) {
            if (resourceItem.height !== strokeWidth) strokeWidth = null;
        } else {
            if (resourceItem.shapeAttribute.strokeWidth / 2 !== strokeWidth)
                strokeWidth = null;
        }
        if (resourceItem.shapeAttribute.strokeDasharray !== strokeDasharray)
            strokeDasharray = 0;
        if (resourceItem.shapeAttribute.radius !== radius) radius = null;
    });

    return { radius, strokeWidth, strokeDasharray, isLine };
};
export const resourceCanEditLine = (
    currentElement: IResourceItemNew | IResourceItemNew,
    resourceItems?: IResourceItemNew[]
) => {
    const funcCheck = (currentElement: IResourceItemNew) => {
        return isResourceLine(currentElement);
    };

    if (currentElement?.resourceIds?.length && resourceItems) {
        let count = 0;
        for (let resourceItem of resourceItems) {
            if (funcCheck(resourceItem)) {
                count++;
            }
        }
        return count > 0;
    }
    return funcCheck(currentElement);
};

export const makeChangeLine = (
    newLine: IShapeAttribute,
    line: IShapeAttribute,
    isStart: boolean
) => {
    let curCommands = getListCommands(line.path),
        commands = getListCommands(newLine.path);
    if (isStart) {
        curCommands[0] = commands[0];
    } else {
        curCommands[1] = commands[1];
    }
    let path = "";
    curCommands.forEach((command) => {
        command.forEach((element) => {
            path += element + " ";
        });
    });
    return isStart
        ? { ...line, path: path.trim(), pathStart: newLine.pathStart }
        : { ...line, path: path.trim(), pathEnd: newLine.pathEnd };
};

export const resourceFixedSize = (currentElment: IResourceItemNew) => {
    const type = currentElment.type;
    return (
        type === ConstantsTool.RESOURCE_IMAGE ||
        type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT
    );
};

export const resourceCanEditTextGroup = (
    currentElement: IResourceItemNew | IResourceItemNew
) => {
    return (
        resourceCanEditTextStyle(currentElement) &&
        currentElement.type !== ConstantsTool.TYPE_RESOURCE_MATH_FORMULA
    );
};

export const resourceCanChangeHeight = (type: number) => {
    return (
        type === ConstantsTool.RESOURCE_TEXT ||
        type === ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
        type === ConstantsTool.TYPE_RESOURCE_MATH ||
        type === ConstantsTool.RESOURCE_TEXT_MODULE
    );
};

export const getDataFromAccessPath = (data: any, accessPath: string) => {
    const accessPathParts = accessPath.split(';');
    let value = data;
    try {
        for (let i = 0; i < accessPathParts.length; i++) {
            value = value[isNaN(+accessPathParts[i]) ? accessPathParts[i] : parseInt(accessPathParts[i])]
        }
    } catch (err) {
        value = ''
    }
    return value;
}

export const generateNewItemID = () => {
    return new Date().getTime().toString() + '_' + Math.floor(Math.random() * 9999).toString();
}

export const convertStringToComponentID = (inputString: string) => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const timestamp = new Date(year, month - 1).getTime();
    inputString = inputString.toLowerCase() + '_' + timestamp;

    return inputString.replace(/[^\w]/g, '_').replace(/^\d/, 'A$&');
}

export const getZoneLabel = (zone: IResourceItemNew) => {
    let label = '';

    if (zone === null || zone === undefined) {
        return label;
    }

    if (zone.parentField) {
        label += zone.parentField;

        if (zone.parentFieldIndex) {
            label += '(' + zone.parentFieldIndex + ')';
        }
        label += ' > ';
    }

    label += zone.fieldName + (zone.fieldIndex ? (' (' + zone.fieldIndex + ')') : '');

    if (zone.linkedZoneFieldName) {
        label += ` > ${zone.linkedZoneFieldName}`;
    }
    return label;
}

export const saveDesignItems = async(dispatch, pageType: string, allItems: IResourceItemNew[], templateID, ideaTemplateID, ideaAnswerSheetPageID, ideaAnswerKeyPageID, ideaThumbnailID, tempTemplateZones: IResourceItemNew[]) => {
    let items: any[] = allItems, url: string;

    if (Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType)) {
        items = allItems.filter(item => {
            if (item.type === Config.RESOURCE_TEXT && item.isNameText) {
                return false;
            }

            return true;
        });
        url = 'auto-content/save-all-design-items/?templateID=' + templateID;
    } else if (pageType === ConstantsTool.PAGE_TYPES['page']) {
        items = allItems;
        url = 'auto-content/save-all-design-items/?ideaTemplateID=' + ideaTemplateID;
    } else if (pageType === ConstantsTool.PAGE_TYPES['answerSheet']) {
        items = allItems;
        url = 'auto-content/save-all-design-items/?ideaAnswerSheetPageID=' + ideaAnswerSheetPageID;
    } else if (pageType === ConstantsTool.PAGE_TYPES['answerKey']) {
        items = allItems;
        url = 'auto-content/save-all-design-items/?ideaAnswerKeyPageID=' + ideaAnswerKeyPageID;
    } else if (pageType === ConstantsTool.PAGE_TYPES['thumbnail']) {
        items = allItems;
        url = 'auto-content/save-all-design-items/?ideaThumbnailID=' + ideaThumbnailID;
    }

    items = items.map(item => {
        if (item.customZoneID) {
            const customZone = tempTemplateZones.find(zone => zone.id === item.customZoneID);
            if (customZone) {
                return customZone;
            }
        }
        return item;
    });

    for (let i = 0; i < tempTemplateZones.length; i++) {
        if (items.find(item => item.id === tempTemplateZones[i].id) === undefined) {
            items.push(tempTemplateZones[i]);
        }
    }

    console.log('save', items);

    const res = await updateDataAPI('POST', url, { items });
    const savedItems = res.data;

    // replace temp id (with _) by system's id
    for (let i = 0; i < items.length; i++) {
        if (items[i].id.indexOf('_') > -1) {
            const savedItem = savedItems.find(item => item.attributes.originalID === items[i].id);
            if (savedItem) {
                const changes = [
                    {
                        attr: 'id',
                        value: savedItem.id.toString(),
                    },
                ];
                if (savedItem['attributes']['originalID']) {
                    changes.push({
                        attr: 'originalID',
                        value: savedItem['attributes']['originalID']
                    });
                }
                if (items[i].type === ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT) {
                    changes.push({
                        attr: 'resourceIds',
                        value: savedItem['childItems']
                    })
                }
                dispatch(
                    updateResourceItem({
                        currentElementId: items[i].id,
                        pageIndex: items[i].pageIndex,
                        attrValues: changes
                    })
                );
            }
        }
    }

    return savedItems;
}

export const syncTemplateLinkedTextToZone = (dispatch, tempTemplateZones, customZoneID: string, value: object) => {
    const customZone = tempTemplateZones.find(zone => zone.id === customZoneID);
    if (customZone) {
        dispatch(
            updateTempTemplateZone({
                zoneID: customZone.id,
                zone: {...customZone, ...value}
            })
        );
    }
}

/**
 * Formats the zone name by converting it to lowercase and replacing spaces with underscores.
 * @param inputName - The input zone name to be formatted.
 * @returns The formatted zone name.
 */
export const formatZoneName = (inputName: string = '') => {
    return inputName.toLowerCase().replace(/\s+/g, '_');
}

export const isResourceItemImage = (resourceItem: IResourceItemNew) => {
    return [
        ConstantsTool.RESOURCE_IMAGE,
        ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE,
        ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES
    ].includes(resourceItem.type);
};

export const isResourceItemText = (resourceItem: IResourceItemNew) => {
    return [
       ConstantsTool.RESOURCE_TEXT
    ].includes(resourceItem.type);
};

export const isPageType = (pageType: string) => {
    return (
        pageType === ConstantsTool.PAGE_TYPES['page']
        || pageType === ConstantsTool.PAGE_TYPES['thumbnail']
        || pageType === ConstantsTool.PAGE_TYPES['answerSheet']
        || pageType === ConstantsTool.PAGE_TYPES['answerKey'])
}