import { useEffect, useState } from 'react';
import './styles.scss';
import { getDataFromAPI } from '../../../../utils/query';
import { useAppSelector } from '../../../../redux/hook';

function TabTemplateVariantsPage({}) {
    const {template} = useAppSelector(state => state.createWorksheetState);

    const [variants, setVariants] = useState([]);

    useEffect(() => {
        fetchVariants();
    }, []);

    const fetchVariants = async() => {
        let originalVariantID: number;
        if (template.originalVariantTemplate) {
            originalVariantID = template.originalVariantTemplate;
        } else {
            originalVariantID = parseInt(template.id);
        }
        const res = await getDataFromAPI('auto-content/templates/?originalVariantID=' + originalVariantID);
        console.log(res.data);
        setVariants(res.data['results']);
    }

    return (
        <div className="tab-template-variants-page">
            <h2>Variants</h2>
        </div>
    );
}

export default TabTemplateVariantsPage;