import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useAppSelector } from '../../../../redux/hook';
import { hasPermission } from '../../../utils';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';

const FieldNameControl = ({
    newFieldName,
    setNewFieldName,
}: {
    newFieldName: string,
    setNewFieldName: (value: string) => void;
}) => {
    const { template, activityPermissions } = useAppSelector((state) => state.createWorksheetState);
    const { staffInfo } = useAppSelector((state) => state.staffInfoState);

    return (
        <Col>
            <Form.Group className="mb-3">
                <Form.Label>Field Name</Form.Label>
                <Form.Control
                    value={newFieldName}
                    onChange={e => setNewFieldName(e.target.value)}
                    disabled={!hasPermission(staffInfo['roles'], activityPermissions[ConstantsTool.CHANGE_ZONE_NAME_ACTION])}
                />
                {template.isUsingInIdea && (
                    <Alert variant='warning' style={{ fontSize: 12, padding: 5 }}>
                        {ConstantsTool.WARNING_WHEN_TEMPLATE_IS_USING_IN_IDEA}
                    </Alert>
                )}
            </Form.Group>
        </Col>
    )
};

export default FieldNameControl;