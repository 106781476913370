import CircularProgress from "@mui/material/CircularProgress";
import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { BackIcon } from "../../../assets/icon/activitiesIcon";
import {
    getResourceProjectData,
    getTemplate,
    requestGetResourceByType,
    searchResourceByKeyword,
    searchTemplateByKeyword,
} from "../../../redux/async/leftData";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
    changeActiveTab,
    toggleShowListActivities,
} from "../../../redux/reducers/leftData";
import ConstantsResource from "../../../resource-user/utils/ConstantsResource";
import Constants from "../../../resource-user/utils/constants";
import Routes from "../../../resource-user/utils/routes";
import ConstantsTool from "../../../shared/utils/ConstantsTool";
import Config from "../../../shared/utils/config";
import {
    getLocalStoreDataWhenClickSeeMoreBgInWeb,
    getPrefixImage,
    getResourceTypeForBackGround,
    getTemplateTypebaseUrl,
    isIndividualTool,
    listActiveTabUsingSearch,
} from "../../../utils";
import MainActivities from "../../ModulesContent";
import LeftListActivitiesV2 from "./ListActivitiesV2";
import "./index.scss";
import TabProject from "./project";
import TabElementsView from "./tab-elements";
import TabShapes from "./tab-shape";
import ListShape from "./tab-shape/listShape";
import TabTemplatesView from "./tab-template";
import TabUploadView from "./tab-upload";
import TextLeftTabContent from "./text-left-tab-content";
import TabComponents from "./tab-components";
import TabTemplatePage from "./tab-template-page";
import TabZones from "./tab-zones";
import TabTemplateVariantsPage from "./tab-template-variants-page";
import TabAssets from './tab-assets';

export const convertCurrentActivityToSearchKey = (activity) => {
    switch (activity) {
        case Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE:
            return "fill in the blank";
        case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
            return "handwriting";
        case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
            return "name tracing";
        case Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE:
            return "word scramble";
        case Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE:
            return "sentence scramble";
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            return "word search";
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            return "crossword";
        case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
            return "multiplication";
        case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
            return "subtraction";
        case Config.ACTIVITY_TYPE.ADDITION.TYPE:
            return "addition";
        case Config.ACTIVITY_TYPE.DIVISION.TYPE:
            return "division";
    }
};

const LeftTabContent = ({
    setShowTabContent,
}: {
    setShowTabContent: (value: boolean) => void;
}) => {
    const userId = useAppSelector(
        (state) => state.userInfoResourceState.data.id
    );
    const loading = useAppSelector((state) => state.leftDataState.loading);
    const isMax = useAppSelector((state) => state.leftDataState.isMax);
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.leftDataState.activeTab);
    const dataTab = useAppSelector((state) => state.leftDataState.dataTab);
    const recentUsedTemplatRedux = useAppSelector(
        (state) => state.recentUseState.listRecentTemplate
    );
    const currentInfo = useAppSelector(
        (state) => state.leftDataState.currentInfo
    );
    const searchKeywordRedux = useAppSelector(
        (state) => state.leftDataState.searchKeyword
    );
    const listBackgroundUsing = useAppSelector(
        (state) => state.leftDataState.listBackgroundUsing
    );
    const currentActivity = useAppSelector(
        (state) => state?.createWorksheetState.currentActivity
    );

    const pagesWorksheet = useAppSelector(
        (state) => state.createWorksheetState.pagesWorksheet
    );

    const isShowListActivities = useAppSelector(
        (state) => state.leftDataState.isShowListActivities
    );
    const loadingCreateWorksheet = useAppSelector(
        (state) => state.createWorksheetState.loading
    );
    const backgroundOrientation = useAppSelector(
        (state) => state.leftDataState.backgroundOrientation
    );
    const [recentUsedTemplate, setRecentUsedTemplate] = useState([]);
    const [showActivities, setShowActivities] = useState(isShowListActivities);
    const refTabContent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let templateType = getTemplateTypebaseUrl();
        if (templateType) {
            let result = recentUsedTemplatRedux.filter(
                (e) => e.originUrl == templateType
            );
            setRecentUsedTemplate(result);
        } else {
            setRecentUsedTemplate(recentUsedTemplatRedux);
        }
    }, [recentUsedTemplatRedux]);

    useEffect(() => {
        let promiseThunk: any;

        if (
            activeTab !== Config.RESOURCE_MODULES &&
            activeTab !== Config.RESOURCE_TEXT
        ) {
            try {
                if (activeTab === Config.RESOURCE_TEMPLATE) {
                    let templateType = getTemplateTypebaseUrl();
                    // if (currentActivity) {
                    if (
                        currentActivity.type ===
                            Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE ||
                        isIndividualTool()
                    ) {
                        promiseThunk = dispatch(
                            getTemplate({
                                templateType: templateType,
                                limit: ConstantsTool.LIMIT_RESOURCE,
                                offset: 0,
                            })
                        );
                    } else
                        promiseThunk = dispatch(
                            searchTemplateByKeyword({
                                offset: 0,
                                size: ConstantsTool.LIMIT_RESOURCE,
                                keyword: convertCurrentActivityToSearchKey(
                                    currentActivity.type
                                ),
                            })
                        );

                    // } else {
                    // dispatch(
                    //     getTemplate({
                    //         worksheetType: Config.TEMPLATE_WORKSHEET,
                    //         limit: ConstantsTool.LIMIT_RESOURCE,
                    //         offset: 1,
                    //     })
                    // );
                    // }
                } else if (activeTab === Config.RESOURCE_PROJECT) {
                    promiseThunk = dispatch(
                        getResourceProjectData({
                            nav: ConstantsResource.TAB_PROJECT.YOUR_EDITS,
                        })
                    );
                } else if (activeTab === Config.RESOURCE_BACKGROUND) {
                    let autoSearchTemplateData =
                        getLocalStoreDataWhenClickSeeMoreBgInWeb();
                    // check to avoid call requestGetResourceByType multipletime
                    if (!autoSearchTemplateData?.worksheetId) {
                        if (backgroundOrientation !== "all") {
                            promiseThunk = dispatch(
                                requestGetResourceByType({
                                    typeResource: [Config.RESOURCE_FRAMES],
                                    limit: ConstantsTool.LIMIT_RESOURCE,
                                    userId,
                                    offset: 1,
                                    orientation: backgroundOrientation,
                                })
                            );
                        } else {
                            promiseThunk = dispatch(
                                requestGetResourceByType({
                                    typeResource: [Config.RESOURCE_FRAMES],
                                    limit: ConstantsTool.LIMIT_RESOURCE,
                                    userId: userId,
                                    offset: 1,
                                    orientation: (pagesWorksheet.length > 0 && pagesWorksheet[0].width > pagesWorksheet[0].height) ? 'horizontal' : 'vertical',
                                })
                            );
                        }
                    }
                } else if (activeTab === Config.RESOURCE_UPLOAD) {
                    promiseThunk = dispatch(
                        requestGetResourceByType({
                            typeResource: [activeTab],
                            limit: ConstantsTool.LIMIT_RESOURCE + 1000,
                            userId: userId,
                            offset: 1,
                        })
                    );
                } else {
                    promiseThunk = dispatch(
                        requestGetResourceByType({
                            typeResource: [activeTab],
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            userId: userId,
                            offset: 1,
                        })
                    );
                }
                if (promiseThunk) {
                    return () => {
                        promiseThunk?.abort();
                    };
                }
            } catch (e) {
                console.log("error change tab", e);
            }
        }
    }, [activeTab, userId]);
    useEffect(() => {
        if (backgroundOrientation) {
            let promiseThunk: any;
            let typeResources = getResourceTypeForBackGround(pagesWorksheet);
            let autoSearchTemplateData =
                getLocalStoreDataWhenClickSeeMoreBgInWeb();
            if (searchKeywordRedux) {
                promiseThunk = dispatch(
                    searchResourceByKeyword({
                        typeResource: typeResources,
                        limit: ConstantsTool.LIMIT_RESOURCE,
                        offset: 1,
                        keyword: searchKeywordRedux,
                        orientation: backgroundOrientation,
                    })
                );
            } else if (userId && !autoSearchTemplateData) {
                promiseThunk = dispatch(
                    requestGetResourceByType({
                        typeResource: typeResources,
                        limit: ConstantsTool.LIMIT_RESOURCE,
                        userId,
                        offset: 1,
                        orientation: backgroundOrientation,
                    })
                );
            }
            if (promiseThunk) {
                return () => {
                    promiseThunk?.abort();
                };
            }
        }
    }, [backgroundOrientation, userId]);
    const handleScroll = throttle(() => {
        if (ref.current) {
            let maxScrollTop =
                ref?.current?.scrollHeight - ref?.current?.clientHeight;
            if (
                ref.current.scrollTop > maxScrollTop * 0.8 &&
                !loading &&
                !isMax &&
                listActiveTabUsingSearch(activeTab)
            ) {
                if (activeTab === Config.RESOURCE_TEMPLATE) {
                    let templateType = getTemplateTypebaseUrl();
                    searchKeywordRedux
                        ? dispatch(
                              searchTemplateByKeyword({
                                  templateType,
                                  offset: dataTab.length,
                                  size: ConstantsTool.LIMIT_RESOURCE,
                                  keyword: searchKeywordRedux,
                              })
                          )
                        : dispatch(
                              getTemplate({
                                  templateType,
                                  limit: ConstantsTool.LIMIT_RESOURCE,
                                  offset: dataTab.length,
                              })
                          );
                } else if (activeTab === Config.RESOURCE_BACKGROUND) {
                    searchKeywordRedux
                        ? dispatch(
                              searchResourceByKeyword({
                                  typeResource: [Config.RESOURCE_FRAMES],
                                  limit: ConstantsTool.LIMIT_RESOURCE,
                                  offset:
                                      dataTab.length /
                                          ConstantsTool.LIMIT_RESOURCE +
                                      1,
                                  keyword: searchKeywordRedux,
                                  orientation: backgroundOrientation,
                              })
                          )
                        : dispatch(
                              requestGetResourceByType({
                                typeResource: [Config.RESOURCE_FRAMES],
                                  limit: ConstantsTool.LIMIT_RESOURCE,
                                  userId: userId,
                                  offset:
                                      dataTab.length /
                                          ConstantsTool.LIMIT_RESOURCE +
                                      1,
                                  orientation: backgroundOrientation,
                              })
                          );
                } else {
                    searchKeywordRedux
                        ? dispatch(
                              searchResourceByKeyword({
                                  typeResource: [activeTab],
                                  limit: ConstantsTool.LIMIT_RESOURCE,
                                  offset:
                                      dataTab.length /
                                          ConstantsTool.LIMIT_RESOURCE +
                                      1,
                                  keyword: searchKeywordRedux,
                              })
                          )
                        : dispatch(
                              requestGetResourceByType({
                                  typeResource: [activeTab],
                                  limit: ConstantsTool.LIMIT_RESOURCE,
                                  userId: userId,
                                  offset:
                                      dataTab.length /
                                          ConstantsTool.LIMIT_RESOURCE +
                                      1,
                              })
                          );
                }
            }
        }
    }, 300);

    const renderData = () => {
        let listIdBackgroundUsing = listBackgroundUsing.map(
            (el) => el.resourceItem.id
        );
        switch (activeTab) {
            case Config.RESOURCE_PROJECT:
                return (
                    <React.Fragment>
                        <TabProject />
                    </React.Fragment>
                );

            case Config.RESOURCE_SHAPES:
                return <TabShapes activeTab={activeTab} />;
            case Config.RESOURCE_MODULES:
                if (showActivities) {
                    return <LeftListActivitiesV2 />;
                } else if (currentActivity?.id && !showActivities) {
                    return <MainActivities></MainActivities>;
                } else {
                    return <></>;
                }
            case Config.RESOURCE_TEXT:
                return <TextLeftTabContent />;
            case Config.RESOURCE_ELEMENT:
                return (
                    <TabElementsView
                        dataTab={dataTab}
                        listIdBackgroundUsing={listIdBackgroundUsing}
                        activeTab={activeTab}
                        parent={refTabContent.current}
                    />
                );
            case Config.RESOURCE_COMPONENTS:
                return (
                    <TabComponents
                        activeTab={activeTab}
                        parent={refTabContent.current}
                        dataTab={dataTab}
                        listIdBackgroundUsing={listIdBackgroundUsing}
                    />
                );
            case Config.ZONES_PAGE:
                return (
                    <TabZones
                        activeTab={activeTab}
                        parent={refTabContent.current}
                        dataTab={dataTab}
                        listIdBackgroundUsing={listIdBackgroundUsing}
                    />
                );
            case Config.RESOURCE_TEMPLATE:
                return (
                    <TabTemplatesView
                        recentUsedTemplate={recentUsedTemplate}
                        activeTab={activeTab}
                        dataTab={dataTab}
                        parent={refTabContent.current}
                    />
                );
            case Config.RESOURCE_UPLOAD:
                return (
                    <TabUploadView
                        dataTab={dataTab}
                        activeTab={activeTab}
                        listIdBackgroundUsing={listIdBackgroundUsing}
                    />
                );
            case Config.RESOURCE_BACKGROUND:
                return (
                    <TabAssets
                        activeTab={activeTab}
                        parent={refTabContent.current}
                        dataTab={dataTab}
                        listIdBackgroundUsing={listIdBackgroundUsing}                    />
                );
            case Config.TEMPLATE_PAGE:
                return (
                    <TabTemplatePage />
                );
            case Config.TEMPLATE_VARIANTS_PAGE:
                return (
                    <TabTemplateVariantsPage />
                );
        }
    };

    useEffect(() => {
        if (activeTab && activeTab === Config.RESOURCE_MODULES) {
            if (
                currentActivity?.type == Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE
            ) {
                setShowActivities(true);
            } else {
                setShowActivities(isShowListActivities);
            }
        } else {
            setShowActivities(true);
        }
    }, [isShowListActivities, currentActivity, activeTab]);

    useEffect(() => {
        if (userId && pagesWorksheet.length > 0) {
            let autoSearchTemplateData =
                getLocalStoreDataWhenClickSeeMoreBgInWeb();
            if (autoSearchTemplateData?.worksheetId) {
                dispatch(changeActiveTab(Config.RESOURCE_BACKGROUND));
                let typeResources =
                    getResourceTypeForBackGround(pagesWorksheet);
                if (
                    typeResources.length == 1 &&
                    typeResources[0] === Config.RESOURCE_FRAMES
                ) {
                    dispatch(
                        requestGetResourceByType({
                            typeResource: typeResources,
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            userId: userId,
                            offset: 1,
                        })
                    );
                } else {
                    dispatch(
                        searchResourceByKeyword({
                            typeResource: typeResources,
                            limit: ConstantsTool.LIMIT_RESOURCE,
                            offset: 1,
                            keyword: autoSearchTemplateData.keyword,
                        })
                    );
                }
                localStorage.setItem(Constants.AUTO_SEARCH_TEMPLATE, "");
            }
        }
    }, [userId, pagesWorksheet]);

    const renderNoneData = () => {
        if (
            dataTab.length === 0 &&
            !loading &&
            activeTab !== Config.RESOURCE_TEXT &&
            activeTab !== Config.RESOURCE_MODULES &&
            activeTab !== Config.RESOURCE_SHAPES &&
            activeTab !== Config.RESOURCE_UPLOAD &&
            activeTab !== Config.TEMPLATE_PAGE &&
            activeTab !== Config.TEMPLATE_VARIANTS_PAGE &&
            activeTab !== Config.RESOURCE_BACKGROUND
        ) {
            return (
                <div className="text-no-result">
                    Sorry, we couldn't find any results for "
                    {searchKeywordRedux}
                    ". Try searching something related.
                </div>
            );
        } else {
            return <div />;
        }
    };

    const getTitleByActivityType = (type: string): string => {
        let result = "";
        for (const activity in Config.ACTIVITY_TYPE) {
            const currentActivity = Config.ACTIVITY_TYPE[activity];
            if (currentActivity?.TYPE === type) {
                result = currentActivity?.TITLE ?? "";
            }
        }

        return result;
    };

    const shouldRenderBackIcon = () => {
        const isMathType = (type: string) => {
            return (
                type === Config.ACTIVITY_TYPE.ADDITION.TYPE ||
                type === Config.ACTIVITY_TYPE.SUBTRACTION.TYPE ||
                type === Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE ||
                type === Config.ACTIVITY_TYPE.DIVISION.TYPE
            );
        };

        if (
            window.location.href.includes(Routes.CUSTOMIZE_WORKSHEET) ||
            window.location.href.includes(Routes.WORKSHEET_MAKER)
        ) {
            if (isMathType(currentActivity?.type) && currentInfo !== null) {
                return false;
            }
            return true;
        }
        return false;
    };

    return (
        <div className="tab-content-container" ref={refTabContent}>
            {shouldRenderBackIcon() && (
                <div
                    className="cluster-back-activity"
                    style={{
                        display: !showActivities ? "flex" : "none",
                    }}
                    onClick={() => dispatch(toggleShowListActivities(true))}
                >
                    <BackIcon />
                    <div className="back-header">
                        {getTitleByActivityType(currentActivity?.type)}
                    </div>
                </div>
            )}
            <SimpleBar
                className="bar-questions"
                style={{
                    maxHeight: "100%",
                    minHeight: "100%",
                    zIndex: 3,
                }}
                scrollableNodeProps={{
                    ref: ref,
                    onScroll: handleScroll,
                }}
            >
                {currentInfo !== null ? (
                    <ListShape
                        activeTab={activeTab}
                        currentInfo={currentInfo}
                    />
                ) : (
                    <div
                        className="main-left-content"
                        style={{
                            padding:
                                (activeTab === Config.RESOURCE_MODULES || activeTab === Config.TEMPLATE_PAGE)
                                    ? "0px 0px 32px 0px"
                                    : "12px 16px 24px",
                        }}
                    >
                        {/* {listActiveTabUsingSearch(activeTab) && (
                            <SearchBar></SearchBar>
                        )} */}
                        {renderData()}
                        {loading &&
                            activeTab !== Config.RESOURCE_SHAPES &&
                            activeTab !== Config.RESOURCE_PROJECT && (
                                <div className="loading-container">
                                    <CircularProgress></CircularProgress>
                                </div>
                            )}
                        {renderNoneData()}
                    </div>
                )}
            </SimpleBar>
            <div
                className="close-button"
                onClick={() => {
                    setShowTabContent(false);
                    dispatch(changeActiveTab(-1));
                }}
            >
                <img
                    src={
                        getPrefixImage() +
                        "/images/create-worksheet/left-content/close-left-content-button.png"
                    }
                ></img>
            </div>
        </div>
    );
};
export default LeftTabContent;
