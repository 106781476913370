import { ANSWER_TYPE } from "../models/answer";

export default class Config {
    static RECENT_MODULE_SELECTED = "recentSelected";
    static RECENT_MODULE_MAKER = "recent_selected_maker";
    static MODULE_MAKER_NAME = {
        INTERACTIVE_MAKER: "INTERACTIVE_MAKER",
        CREATE_WORKSHEET: "CREATE_WORKSHEET",
        CUSTOMIZE_WORKSHEET: "CUSTOMIZE_WORKSHEET",
        HANDWRITING: "HANDWRITING",
        MULTIPLE_CHOICES: "MULTIPLE_CHOICES",
        CROSSWORD: "CROSSWORD",
        WORD_SEARCH: "WORD_SEARCH",
        FILL_IN_THE_BLANK: "FILL_IN_BLANK",
        NAME_TRACING: "NAME_TRACING",
    };
    static MODULE_NAME = "";
    static MODULE_NAME_CONFIG = {
        WEB: "web",
        CLASSROOM: "classroom",
        TOOL_V1: "toolv1",
        TOOL_V2: "toolv2",
    };
    static MODULE_APPLY_FOR_NEXT_JS = false;
    static BASE_URL = "http://localhost:3001/api";
    static BASE_URL_WEB_PUSH = "https://webpush.passemall.com/api";
    // static BASE_URL = "https://api-staging.tests-hub.com/api";
    static CDL_CATEGORYid = "6066bda8a2c0602cd4233ddf";
    static HTTP_REQUEST_TIMEOUT = 30000;
    static HTTP_REQUEST_SUCCESS = 200;
    static LIMIT = 15;
    static LIMIT_TEST = 3;
    static jwtSecret = "koolsoftdev!2020";
    static KEY_ENCRYPT_DATA = "TbEQb0TDG9D64Xt544xLFofSBmxtJ7l6";
    // static KEY_ENCRYPT_DATA = "TruongminhnguyeN";
    static PUSH_SERVER_PUBLICKEY =
        "BIN2Jc5Vmkmy-S3AUrcMlpKxJpLeVRAfu9WBqUbJ70SJOCWGCGXKY-Xzyh7HDr6KbRDGYHjqZ06OcS3BjD7uAm8";

    static TOKEN_EXPIRED = "7d";

    // 24 * 60 * 60 = 1d
    static API_KEY_DEV = "AIzaSyAhdGm7BhwG_sJ2iHSv5e-TRzMVU0hDXrE";
    static CLIENTid_DEV =
        "519631446724-ac2qtq6bpfkc318rigpqggktiot3p32s.apps.googleusercontent.com";

    static API_KEY_PROD = "AIzaSyD8mQDOKcI3AyxHf0Egxqn6HEy7suYlzdM";
    static CLIENTid_PROD =
        "792314426707-f67a15q8kejrn1lb1pcbjeo45gt74teb.apps.googleusercontent.com";

    static LOGIN_FAILED = -1;
    static SNACKBAR_TYPE_SUCCESS = "success";
    static SNACKBAR_TYPE_FAILED = "error";
    static TOPICS_SELECT_MOBILE = 1;
    static STATES_SELECT_MOBILE = 2;
    static LOGIN_SUCCESS = 0;
    static LOGIN_ACCOUNT_IS_USED = 1;
    static LOGIN_ACCOUNT_NOT_EXIST = 2;
    static LOGIN_WRONG_PASSWORD = 3;
    static LOGIN_WRONG_PROVIDER = 4;
    static LOGIN_ACCOUNT_NOT_ACTIVATED = 5;
    static LOGIN_MOBILE_IS_USED = 6;
    static DEFAULT_USER = "user";
    static MILLISECONDS_PER_DAY = 86400000;
    static MILISECOND_PER_MONTH = 30 * 24 * 60 * 60 * 1000;
    static CHANGE_PASS_FAILED = -1;
    static CHANGE_PASS_SUCCESS = 0;
    static CHANGE_PASS_WRONG_PASSWORD = 2;
    static CHANGE_PASS_NOT_FOUND_USER = 3;

    static EXAM_TYPE_MANUAL = 0;
    static EXAM_TYPE_AUTOMATIC = 1;

    static CATEGORY_CHILDREN_TYPE_CATEGORY = 0;
    static CATEGORY_CHILDREN_TYPE_STATE = 1;
    static CATEGORY_CHILDREN_TYPE_CARD = 2;
    static CATEGORY_CHILDREN_TYPE_TOPIC_QUESTION = 3;
    static CATEGORY_CHILDREN_TYPE_UNKNOWN = 4;

    static STATUS_DELETED = -1;
    static STATUS_PRIVATE = 0;
    static STATUS_PUBLIC = 1;
    static STATUS_TESTING = 2;
    static STATUS_WAITING = 3;
    static STATUS_OPEN = 4;
    static STATUS_EXPIRED = 5;
    static STATUS_DRAFT = 6;
    static STATUS_CREATE_SUCCESS = 7;
    static STATUS_CRAWLER_FROM_LIVEWS = 8;

    static STATUS_NON_PUBLIC_REASON = {
        NON_TITLE: 10,
        LANGUAGE_NOT_IS_ENG: 11,
        USER_TEST: 12,
        OTHER_REASONS: 13,
        MANY_BLANKS: 14,
    };

    static STATUS_CATEGORY = {
        PUBLIC: 1,
        PRIVATE: 2,
        DELETE: -1,
    };

    static EXAM_SCORE_DO_NOT_YET = 1;
    static EXAM_SCORE_PLAYING = 2;
    static EXAM_SCORE_FINISHED = 3;
    static EXAM_SCORE_PAUSED = 4;

    static USER_PROVIDER_REGISTER = 0;
    static USER_PROVIDER_GOOGLE = 1;
    static USER_PROVIDER_FACEBOOK = 2;
    static USER_PROVIDER_APPLE = 3;
    static USER_PROVIDER_AUTO_GENERATE = 4;
    static USER_PROVIDER_EMAIL = 5;

    static CATEGORY_CHILDRENT_TYPE_CATEGORY = 1;
    static CATEGORY_CHILDRENT_TYPE_STATE = 2;

    static CARD_IS_CHILD = 2;
    static CARD_HAS_CHILD = 1;
    static CARD_NORMAL = 0;

    static QUESTION_MULTI_CHOICE = 0;
    static QUESTION_TYPING = 1;

    static API =
        "https://dev-dot-micro-enigma-235001.appspot.com/dataapi?type=";

    static VALUE_DEFAULT_TIME = -1;
    static VALUE_DEFAULT_NUMBER = 0;
    static VALUE_DEFAULT_TEXT = "";
    static VALUE_DEFAULT_BOOL = false;

    static UPLOAD_FILE_IMAGE = 1;
    static UPLOAD_FILE_SOUND = 2;
    static UPLOAD_FILE_XLS = 3;
    static UPLOAD_FILE_PDF = 4;

    static AUTHORid = "606a6cca66d7c705a2b4f3dd";

    static TEST_GAME_TYPE_INSTANCE_FEEDBACK = 1;
    static TEST_GAME_TYPE_NOT_INSTANCE_FEEDBACK = 2;
    static GOOGLE_CLOUD_STORAGE_URL = "https://storage.googleapis.com/";
    static GOOGLE_CLOUD_STORAGE_URL_SHORT = "https://storage.googleapis.com";
    static AVATAR_COURSE_DEFAULT_URL = "/images/avatar-course-default.jpeg";

    static UNDERSTANDING_LEVEL_WEAK = 0;
    static UNDERSTANDING_LEVEL_MEDIUM = 1;
    static UNDERSTANDING_LEVEL_STRONG = 2;

    static TYPE_MY_TEST_RECENT = 0;
    static TYPE_MY_TEST_CREATE = 1;
    static TYPE_PROFILE = 2;

    static STUDY_UNLOCK_SCORE = 50;
    static HAS_FLASH_CARD = false;

    static COURSE_SOURCE_SYSTEM = 0;
    static COURSE_SOURCE_USER = 1;
    static COURSE_SOURCE_QUIZLET = 2;

    static APPid_HAS_STATE = ["5722070642065408", "5082322646859776"];
    static APP_HAS_CHILD_CATEGORY = [
        "5722070642065408",
        "5082322646859776",
        "5734055144325120",
        "5657969412800512",
        "5681717746597888",
    ];
    static APP_MAIN_CATEGORY_NAME = ["asvab", "cdl", "dmv", "g1", "hesia2"];
    static STUDY_TYPE_PRACTICE_AND_TEST = 0;
    static STUDY_TYPE_PRACTICE = 1;
    static STUDY_TYPE_TEST = 2;

    static TEST_SYSTEM_CREATE = 0;
    static TEST_USER_CREATE = 1;
    static TEST_ASSESSMENT = 2;

    static GAME_PLAY_TEST = 0;
    static GAME_PLAY_PRACTICE = 1;
    static GAME_PLAY_REVIEW = 2;

    static ASSESSMENT_TEST = 1;
    static TOPIC_TEST = 2;
    static FULL_TEST = 3;
    static LIMIT_ASSESSMENT_TEST = 2;

    static TYPE_USER_CREATED = 0;
    static TYPE_USER_CLONE = 1;
    static ASSESSMENT_TEST_NAME = "assessment test";
    static TOPIC_TEST_NAME = "full test";
    static FULL_TEST_NAME = "topic test";

    static GAME_LAYOUT_DEFAULT = 0;
    static GAME_LAYOUT_ONE_QUESTION = 1;

    static GAME_SHOW_ANSWER = 0;
    static GAME_NOT_SHOW_ANSWER = 1;

    static SHOW_VALUE = 1;
    static HIDE_VALUE = 0;

    static IDEAL_TIME_NOT_SPECIFIC = 0;
    static IDEAL_TIME_EARLY_MORNING = 1;
    static IDEAL_TIME_AFTER_WORK = 2;
    static IDEAL_TIME_BEFORE_BED = 3;

    static NOTIFY_ENABLE = 0;
    static NOTIFY_DISABLE = 1;

    static LIBRARY_TAB_QUESTION = 0;
    static LIBRARY_TAB_TEST = 1;
    static LIBRARY_TAB_CATEGORY = 2;
    static LIBRARY_TAB_YOUR_TEST = 3;

    static UNDEFINED_CATEGORY = 0;
    static USER_CREATED_CATEGORY = 1;

    static ORDER_SEQUENTIALLY = 0;
    static ORDER_SHUFFLE = 1;
    static TEMPLATE_TAG = 1;
    static WORKSHEET_TAG = 2;
    static TEMPLATE_CATEGORY = 1;
    static WORKSHEET_CATEGORY = 2;
    static RESOURCE_TEMPLATE = 1;
    static RESOURCE_TEXT_TEMPLATE = 8;
    static TYPE_RESOURCE_MODULE = 10;
    static TYPE_RESOURCE_BORDER = 11;
    static TYPE_TEXT_FILL_THE_BLANK = 12;
    static TYPE_WORD_BANK = 13;

    static RESOURCE_SURVEY = 20;
    static RESOURCE_CLASS = 30;
    static RESOURCE_TREE = 21;
    static RESOURCE_REPORT_BUG = 22;
    static RESOURCE_ACCOUNTS = 23;
    static RESOURCE_EXPORT_DATA = 24;

    static RESOURCE_BACKGROUND_TEMPLATE = 9; // dùngKhi select tempate lần đầu

    static RESOURCE_WORKSHEET = 6;
    static RESOURCE_COLLECTION = 7;
    static RESOURCE_KEYWORD_LIST = 17;
    static RESOURCE_KEYWORD = 8;

    static RESOURCE_BACKGROUND = 2;
    static RESOURCE_FRAMES = 3;
    static RESOURCE_TEXT = 4;
    static RESOURCE_ELEMENT = 5;
    static RESOURCE_MODULES = 6;
    static RESOURCE_UPLOAD = 7;
    static RESOURCE_COMPONENTS = 8;
    static RESOURCE_DEFAULT_BACKGROUND = 10;
    static RESOURCE_LINE = 11;
    static RESOURCE_SHAPES = 12;
    static RESOURCE_TEST = 13;
    static RESOURCE_ERASER = 14;
    static RESOURCE_WORD_SEARCH_TEXT = 15;
    static RESOURCE_CALCULATION = 16;
    static RESOURCE_CROSSWORD_TEXT = 17;
    static RESOURCE_TEXT_MODULE = 18;
    static RESOURCE_BACKGROUND_FRAMES = 19;
    static RESOURCE_PROJECT = 20;
    static RESOURCE_FONT_FAMILIES = 26;
    static TEMPLATE_PAGE = 30;
    static ZONES_PAGE = 31;
    static TEMPLATE_VARIANTS_PAGE = 32;
    static MONTH_NAMES = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    static STATUS_VERIFY_ERROR = -1;
    static STATUS_VERIFY_EXPIRED = 0;
    static STATUS_VERIFY_SUCCESS = 1;
    static STATUS_EMAIL_AVAILABLE = 1;
    static STATUS_EMAIL_EXPIRED = 0;
    static STATUS_EMAIL_NOT_AVAILABLE = -1;
    static EMAIL_VERIFY_EXPIRED_TIME = 30;

    static NORMAL_WORKSHEET = 0;
    static PDF_WORKSHEET = 1;
    static TEMPLATE_WORKSHEET = 3;

    static CATEGORY_TYPE_NORMAL = 0;
    static CATEGORY_TYPE_SEO = 1;
    static CATEGORY_TYPE_SUBJECT = 2;
    static CATEGORY_TYPE_TOPIC = 3;
    static CATEGORY_TYPE_COMMON_CORE = 4;
    static CATEGORY_TYPE_LESSON_PLAN = 5;

    static STATUS_DELETE_WORKSHEET = 2;
    static STATUS_PUBLIC_WORKSHEET = 0;
    static STATUS_PRIVATE_WORKSHEET = 1;

    static COLLECTION_TYPE_NONE = -1;
    static COLLECTION_TYPE_TEAM_CONTENT = 0;
    static COLLECTION_TYPE_USER_CREATED = 1;
    static COLLECTION_TYPE_EXAM = 2;

    static ERROR = 0;
    static SUCCESS = 1;
    static RESOURCE_TUTORIAL = 0;
    static RESOURCE_RECTANGLE = 1;
    static RESOURCE_ELLIPSE = 2;
    static RESOURCE_PEN = 3;
    static DRAFT_COLLECTION = "draft";
    static DRAFT_COLLECTIONid = "MY_DRAFTS";
    static DRAFT_COLLECTION_NAME = "My Drafts";
    static DOWNLOAD_COLLECTION = "download";
    static DOWNLOAD_COLLECTION_NAME = "My downloads";
    static DOWNLOAD_COLLECTIONid = "DOWNLOAD_COLLECTION_ABC";
    static PRACTICE_COLLECTION = "practice";
    static PRACTICE_COLLECTION_NAME = "My played worksheets";
    static PRACTICE_COLLECTIONid = "PRACTICE_COLLECTION_ABC";
    static WORKSHEETS_CREATED_COLLECTION = "worksheets created";
    static WORKSHEETS_CREATED_COLLECTION_NAME = "My worksheets";
    static WORKSHEETS_CREATED_COLLECTIONid =
        "WORKSHEETS_CREATED_COLLECTIONid";
    static DELETE_MY_WORKSHEET = "delete my worksheet";
    static DELETE_DRAFT_WORKSHEET = "delete draft worksheet";
    static LIST_NAME_RESOURCE = [
        "",
        "template",
        "background",
        "frames",
        "text",
        "element",
        "modules",
        "upload",
        "",
        "",
        "",
        "",
        "shapes",
    ];
    static NOT_CRAWL_FREEPIK = 1;
    static CRAWLED_FREEPIK = 2;

    static PAINTING = "painting";
    static ERASER = "eraser";
    static HINT = "hint";
    static DRAW_TEXT = "draw-text";
    static DRAW_LINE = "draw-line";
    static BACKGROUND_COLOR_LIST = [
        "#FFFFFF",
        "#000000",
        "#62AA2C",
        "#CBE426",
        "#FDFB2D",
        "#F5B702",
        "#F79100",
        "#FB4F05",
        "#FB2410",
        "#A41646",
        "#8500AB",
        "#3E009F",
        "#0345FD",
        "#028CCA",
        // "#FAAF96",
        // "#BE6E82",
    ];

    static USER_ROLE_NORMAL = 0;
    static USER_ROLE_CONTENT_MANAGER = 3;
    static USER_ROLE_ADMIN = 99;
    static USER_ROLE_TESTER = 2;

    static COLLECTION_DEMO = "633d2d83a906c33dcb52882e";
    static COLLECTION_PREMIUM = "633d3198a906c33dcb52b56d";
    static MICRO_URL = "https://micro-enigma-235001.appspot.com";

    static TYPE_VIDEO = "video";
    static TYPE_TEMPLATE = 3;
    static IMAGE_TYPE = ["image/png", "image/jpeg", "image/jpg"];
    static PDF_TYPE = "application/pdf";
    static LIMIT_FILE_SIZE = 10000000;

    static TOPIC_ENGLISH = "617925b8ab49524248b5ed83";
    static FILTER_COLLECTION = "collections";
    static FILTER_INTERACTIVE = "interactive";
    static FILTER_ALL = "all";

    // ENTER WORKSHEET MAKER
    static ENTER_WM_BLANK = "enter_wm_blank";
    static ENTER_WM_DIGITAL_TEMPLATE = "enter_wm_digital_template";
    static ENTER_WM_FIXED_WORKSHEET = "enter_wm_fixed_worksheet";

    // SEARCH
    static LIST_SEARCH_ITEM = [
        "",
        "search_template",
        "search_background",
        "search_frames",
        "search_text",
        "search_element",
        "search_modules",
        "search_upload",
        "",
        "",
        "",
        "",
        "search_shape",
    ];

    // CANVAS ACTIVITY
    static SEND_BACKWARD_ITEM = "send_backward_item";
    static SEND_TO_BACK_ITEM = "send_to_back_item";
    static BRING_FORWARD_ITEM = "bring_forward_item";
    static BRING_TO_FRONT_ITEM = "bring_to_front_item";
    static MOVE_ITEM = "move_item";
    static TRANSFORM_ITEM = "transform_item";
    static CHANGE_COLOR_ITEM = "change_color_item";
    static CHANGE_FONT_COLOR_ITEM = "change_font_color_item";
    static CHANGE_FONT_SIZE_ITEM = "change_font_size_item";
    static CHANGE_FONT_FAMILY_ITEM = "change_font_family_item";
    static DUPLICATE_ITEM = "duplicate_item";
    static DELETE_ITEM = "delete_item";
    static COPY_ITEM = "copy_item";
    static PASTE_ITEM = "paste_item";
    static ADD_PAGE = "add_page";
    static REMOVE_PAGE = "remove_page";
    static LIST_ADD_ITEM = [
        "",
        "add_template_item",
        "add_background_item",
        "add_frame_item",
        "add_text_item",
        "add_element_item",
        "add_module_item",
        "add_upload_item",
        "",
        "",
        "",
        "",
        "add_shape_item",
    ];

    // CHANGE VIEW
    static LIST_CLICK_MAIN_VIEW = [
        "click_view_editor",
        "click_view_make_interactive",
        "click_view_preview_interactive",
    ];
    static LIST_CLICK_VIEW = [
        "",
        "click_view_template",
        "click_view_background",
        "click_view_frames",
        "click_view_text",
        "click_view_element",
        "click_view_modules",
        "click_view_upload",
        "",
        "",
        "",
        "",
        "click_view_shape",
    ];
    static OPEN_POPUP_SAVE = "open_popup_save";
    static OPEN_POPUP_LOGIN = "open_popup_login";
    static LOGIN_WITH_EMAIL = "login_with_email";
    static LOGIN_WITH_GOOGLE = "login_with_google";
    static SAVE_WORKSHEET = "save_worksheet";

    static USER_CREATED_WS = "user_created_ws";
    static USER_CREATED_WORD_SEARCH = "user_created_wse";
    static USER_CREATED_TOOL_V2_WORD_SEARCH =
        "tool_v2_user_created_word_search";
    static USER_CREATED_TOOL_V2_HAND_WRITING =
        "tool_v2_user_created_hand_writing";
    static USER_CREATED_TOOL_V2_NAME_TRACING =
        "tool_v2_user_created_name_tracing";
    static USER_CREATED_TOOL_V2_FILL_THE_BLANK =
        "tool_v2_user_created_fill_the_blank";
    static USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET =
        "tool_v2_user_customize_worksheet";
    static USER_CREATED_TOOL_V2_MULTIPLE_CHOICES =
        "tool_v2_user_created_multiple_choices";
    static USER_CREATED_TOOL_V2_CROSSWORD = "tool_v2_user_created_crossword";
    static USER_CREATED_TOOL_V2_ADDITION = "tool_v2_user_created_addition";
    static USER_CREATED_TOOL_V2_SUBTRACTION =
        "tool_v2_user_created_subtraction";
    static USER_CREATED_TOOL_V2_MULTIPLICATION =
        "tool_v2_user_created_multiplication";
    static USER_CREATED_TOOL_V2_DIVISION = "tool_v2_user_created_division";
    static USER_CREATED_TOOL_V2_WORD_SCRAMBLE =
        "tool_v2_user_created_word_scramble";
    static USER_CREATED_TOOL_V2_SENTENCE_SCRAMBLE =
        "tool_v2_user_created_sentence_scramble";

    static DATE_START_USER_CREATED_WS = "2022-11-02T17:00:00.000Z";
    static RETURN_USER = 1;
    static NOT_RETURN = 2;
    static GRID_VIEW_TYPE = 0;
    static LIST_VIEW_TYPE = 1;
    static ALPHABETICAL_SORT = "Alphabetical";
    static DATE_CREATED_SORT = "Date created";
    static LAST_EDITED_SORT = "Last edited";
    static OLDEST_FIRT_ORDER = 1;
    static NEWEST_FIRT_ORDER = -1;
    static WORKSHEET_LINK_PARAMS =
        "?utm_source=copy_url&utm_medium=share_from_wsz";

    static WORKSHEET_TYPE_INTERACTIVE = 0;
    static WORKSHEET_TYPE_PDF = 1;
    static WORKSHEET_TYPE_ALL = 2;
    static WORKSHEET_TYPE_CREATE_TOOLV2 = 3;

    static INTERACTIVE_IMAGE_SIZE = 1000;
    static START_MODE_CREATE_WORKSHEET = 0;
    static START_MODE_MAKE_INTERACTIVE = 1;
    static CREATE_WS_FLOW = 0;
    static MAKE_INTERACTIVE_FLOW = 1;
    static CUSTOMIZE_WS_FLOW = 2;

    static CHECKED_KEYWORD = 1;

    static LEVEL_HIGH = "high";
    static LEVEL_MEDIUM = "medium";
    static LEVEL_LOW = "low";
    static UPLOAD_TYPE_PAGE = 0;
    static UPLOAD_TYPE_ITEM = 1;

    static DEFAULT_LIMIT_PAGE = 50;

    static BOTTOM_BAR_MODE_PAGE = 0;
    static BOTTOM_BAR_MODE_RESOURCE = 1;
    static BOTTOM_BAR_MODE_ATTRIBUTE = 2;
    static BOTTOM_BAR_MODE_ATTRIBUTE_DETAIL = 3;

    static ATTRIBUTE_EDIT_TEXT = {
        NONE: -1,
        EDIT: 0,
        FONT: 1,
        FONT_SIZE: 2,
        COLOR: 3,
        FORMAT: 4,
        MORE: 5,
    };

    static CMS_STATUS_FILTER = {
        searchKeyword: "searchKeyword",
        searchExcelFile: "searchExcelFile",
        searchIds: "searchIds",
        author: "author",
        userCreated: "userCreated",
        searchAccounts: "searchAccounts",
    };

    static CMS_FILTER_OWNER = {
        TEAM_CONTENT: "Content",
        USER: "User",
    };

    static CMS_FILTER_TYPE_INTERACTIVE = {
        INTERACTIVE: "INTERACTIVE",
        NON_INTERACTIVE: "NON_INTERACTIVE",
    };

    static CMS_FILTER_STATUS = {
        PUBLIC: "PUBLIC",
        CREATE_SUCCESS: "CREATE_SUCCESS",
        CRAWLER_FROM_LIVEWS: "CRAWLER_FROM_LIVEWS",
    };
    static CMS_STATUS_FUNCTION = {
        delete: "delete",
        public: "public",
        checked: "checked",
        merge: "merge",
    };

    static CMS_STATUS_FORM = {
        detail: "detail",
        imageZoom: "imageZoom",
    };

    static CMS_STATUS_UPDATE = {
        update: "update",
        delete: "delete",
        public: "public",
        index: "index",
    };

    static FLAG_INDEX_WS = 1;
    static NONE_FLAG_INDEX_WS = 0;

    static RESOURCE_USER_REPORT = 9;

    static CMS_FILTER_ROLE = {
        TEACHER: "Teacher",
        STUDENT: "Student",
        PARENT: "Parent",
    };
    static CMS_FILTER_GRADE = {
        PRE_K_2: "PreK-2",
        MIDDLE_SCHOOL: "Middle School",
        ADULT_EDUCATION: "Adult Education",
        HIGHER_EDUCATION: "Higher Education",
        HOMESCHOOL: "Homeschool",
        GRADES_3_5: "Grades 3-5",
    };
    static CMS_FILTER_INTERESTED = {
        ENGLISH: "English",
        SOCIAL_STUDIES_HISTORY: "Social Studies/History",
        SPECIAL_EDUCATION: "Special Education",
        ART_DRAMA_MUSIC: "Art/Drama/Music",
        MULTIPLE_ELEMENTARY_SUBJECTS: "Multiple Elementary Subjects",
        MATH: "Math",
        SCIENCE: "Science",
        FOREIGN_LANGUAGE: "Foreign Language",
        PE_HEALTH: "PE/Health",
        OTHER: "Other",
    };
    static CMS_FILTER_MAIN_PURPOSE = {
        DOWNLOAD_WORKSHEETS: "Download worksheets",
        MAKE_MY_OWN_WORKSHEETS: "Make my own worksheets",
        CREATE_INTERACTIVE_WORKSHEETS: "Create interactive worksheets",
        MANAGE_MY_CLASSES: "Manage my classes",
        OTHERS: "Others",
    };
    static CMS_FILTER_SOURCE = {
        WORD_SEARCH_MAKER: "word-search-maker",
    };

    static TYPE_COLLECTION = "collection";
    static TYPE_CATEGORY = "category";
    static TYPE_FOLDER_COLLECTION = "TYPE_FOLDER_COLLECTION";
    static TYPE_CATEGORY_COLLECTION = "category-collection";

    static PAGE_TYPE = {
        PAGE_DEFAULT: 0,
        PAGE_COLLECTION_DETAIL: 1,
        PAGE_VIEW_COLLECTION_DETAIL: 2,
    };

    static TYPE_GRID = {
        TYPE_FOLDER_COLLECTION: "TYPE_FOLDER_COLLECTION",
        TYPE_CREATE_COLLECTION: "TYPE_CREATE_COLLECTION",
        TYPE_KEYWORD_GPT_BLOCK: "TYPE_KEYWORD_GPT_BLOCK",
    };

    static COLLECTION_DRAFT = {
        DRAFT_COLLECTION: "draft",
        DRAFT_COLLECTIONid: "MY_DRAFTS",
        DRAFT_COLLECTION_NAME: "My Drafts",
    };

    static COLLECTION_DOWNLOAD = {
        DOWNLOAD_COLLECTION: "download",
        DOWNLOAD_COLLECTIONid: "DOWNLOAD_COLLECTION_ABC",
        DOWNLOAD_COLLECTION_NAME: "My downloads",
    };

    static COLLECTION_PRACTICE = {
        PRACTICE_COLLECTION: "practice",
        PRACTICE_COLLECTIONid: "PRACTICE_COLLECTION_ABC",
        PRACTICE_COLLECTION_NAME: "My played worksheets",
    };

    static COLLECTION_WORKSHEETS_CREATED = {
        WORKSHEETS_CREATED_COLLECTION: "My Created Worksheets",
        WORKSHEETS_CREATED_COLLECTIONid: "WORKSHEETS_CREATED_COLLECTIONid",
        WORKSHEETS_CREATED_COLLECTION_NAME: "My Created Worksheets",
    };

    static COLLECTION_MY_COLLECTIONS = {
        COLLECTION_MY_COLLECTIONS: "My Collections",
        COLLECTION_MY_COLLECTIONSid: "COLLECTION_MY_COLLECTIONSid",
        COLLECTION_MY_COLLECTIONS_NAME: "My Collections",
    };
    static LENGTH_RECENT_WSidS = 5;
    static CLICK_WS_SCORE = 1;
    static RECENT_WSidS = "recent-ws-ids";
    // static MATH_CATEGORYid = "617925b8ab49524248b5f04a";
    // static HANDWRITING_CATEGORYid = "62eb3635432c563f64ca5f03";
    // static ENGLISH_LANGUAGE_ART_CATEGORYid = "617925b8ab49524248b5ef65";
    // static SCIENCE_CATEGORYid = "62c7a827a16aa3371fc79253";
    // static COLORING_PAGES_CATEGORYid = "6323cad7cf44760dce895a7f";
    // static OTHER_CATEGORYid = "6327df3b9e095ed459e956a5";
    // static BEHAVIOR_ACTIVITIES_CATEGORYid = "62f5a64aed7fe33536bd084b";

    static CATEGORY_CHILDidS = [
        // Config.MATH_CATEGORYid,
        // Config.HANDWRITING_CATEGORYid,
        // Config.ENGLISH_LANGUAGE_ART_CATEGORYid,
        // Config.SCIENCE_CATEGORYid,
        // Config.COLORING_PAGES_CATEGORYid,
        // Config.OTHER_CATEGORYid,
        // Config.BEHAVIOR_ACTIVITIES_CATEGORYid,
    ];

    static LIMIT_DATA = { LIMIT_WORKSHEET: 30 };
    static FOLDER_DEFAULT = {
        DRAFT_COLLECTIONid: Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid,
        DOWNLOAD_COLLECTIONid:
            Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid,
        PRACTICE_COLLECTIONid:
            Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid,
        WORKSHEETS_CREATED_COLLECTIONid:
            Config.COLLECTION_WORKSHEETS_CREATED
                .WORKSHEETS_CREATED_COLLECTIONid,
    };
    static ACTION_GRID = {
        DEFAULT: "",
        DELETE_WS: "DELETE_WS",
        DELETE_WSS: "DELETE_WSS",
        DELETE_COLLECTION: "DELETE_COLLECTION",
        MOVE_COLLECTION: "MOVE_COLLECTION",
        MOVE_WORKSHEET: "MOVE_WORKSHEET",
        EDIT: "EDIT",
        SAVE: "SAVE",
        SAVE_RESOURCE_ASSIGN: "SAVE_RESOURCE_ASSIGN",
        CREATE_COLLECTION: "CREATE_COLLECTION",
        OPEN_ASSIGN_DIGITALLY: "OPEN_ASSIGN_DIGITALLY",
        CLEAR_WSS: "CLEAR_WSS",
        DOWNLOAD_WSS: "DOWNLOAD_WSS",
        PRINT_WSS: "PRINT_WSS",
        INVITE_FRIEND: "INVITE_FRIEND",
    };
    static LENGTH_RECENT_WS = 5;

    static LOGIN_TYPE = {
        DIGITAL_WORKSHEET_MAKER_APP: "DIGITAL_WORKSHEET_MAKER_APP",
    };
    static TYPE_ALERT = {
        ALERT_ACTION_WEB: "ALERT_ACTION_WEB",
        ALERT_ACTION_ADD_CLASSROOM: "ALERT_ACTION_ADD_CLASSROOM",
    };
    static ACTION_ALERT = {
        ADD: "ADD",
        REMOVE: "REMOVE",
    };

    static STATUS_PUZZLE = {
        INIT: -1,
        FOUND: 1,
    };

    static NEXT_ACTION_WORD_SEARCH = {
        INIT: "",
        ADD_TO_COLLECTION: "ADD_TO_COLLECTION",
        DOWNLOAD_ONLY: "DOWNLOAD_ONLY",
        DOWNLOAD_WORD: "DOWNLOAD_WORD",
        DOWNLOAD_ANSWER: "DOWNLOAD_ANSWER",
        ASSIGN_DIGITALLY: "ASSIGN_DIGITALLY",
        SHARE_PINTEREST: "SHARE_PINTEREST",
        SHARE_TWITTER: "SHARE_TWITTER",
        SHARE_WHATSAPP: "SHARE_WHATSAPP",
        SHARE_FACEBOOK: "SHARE_FACEBOOK",
        SHARE_MICROSOFT_TEAMS: "SHARE_MICROSOFT_TEAMS",
        SHARE_GOOGLE_CLASSROOM: "SHARE_GOOGLE_CLASSROOM",
        COPY_WORKSHEET_LINK: "COPY_WORKSHEET_LINK",
        SHARE_EMBED_LINK: "SHARE_EMBED_LINK",
        ADD_TO_MY_RESOURCES: "ADD_TO_MY_RESOURCES",
        SAVE: "SAVE",
        SAVE_AND_VIEW: "SAVE_AND_VIEW",
    };

    static NEXT_ACTION_SAVE_WORKSHEET = "NEXT_ACTION_SAVE_WORKSHEET";
    static NEXT_ACTION_PRINT_WORKSHEET = "NEXT_ACTION_PRINT_WORKSHEET";

    static DEFAULT_ROW_STUDENT_INFO = ["Name", "Grade"];
    static DEFAULT_SHAPE_NAME = "Square";
    static TYPE_WORD_SEARCH = 1;
    static TYPE_INTERACTIVE = 2;
    static TYPE_DIVISION = 3;
    static EFFECT_NONEid = 0;

    static MODULE_FILL_THE_BLANK = 4;
    static MODULE_MULTIPLE_CHOICES = 5;

    static EFFECT_SHADOWid = 1;
    static EFFECT_LIFTid = 2;
    static EFFECT_HOLLOWid = 3;
    static EFFECT_SPLICEid = 4;
    static EFFECT_ECHOid = 5;
    static EFFECT_GLITCHid = 6;
    static EFFECT_NEONid = 7;
    static EFFECT_BACKGROUNDid = 7;
    static SORT_BY_UPDATED_DATE = "Latest";
    static SORT_BY_TITLE = "A-Z";
    static SORT_BY_POPULARITY = "Popular";

    static CMS_FILTER_SOURCE_TYPE = {
        UPLOAD_IMAGE: "Upload Image",
        EDIT_WORKSHEET: "Edit Worksheet",
        USER_CREATED_TOOL_V2_WORD_SEARCH: "Create By Word Search",
        USER_CREATED_TOOL_V2_HAND_WRITING: "Create By HandWriting",
        USER_CREATED_TOOL_V2_FILL_THE_BLANK: "Create By Fill The Blank",
        USER_CREATED_TOOL_V2_NAME_TRACING: "Create By Name Tracing",
        USER_CREATED_TOOL_V2_SCRATCH: "Create From Scratch",
        USER_CREATED_TOOL_V2_CROSSWORD: "Create By Crossword",
        USER_CREATED_TOOL_V2_ADDITION: "Create By Addition",
        USER_CREATED_TOOL_V2_SUBTRACTION: "Create By Subtraction",
        USER_CREATED_TOOL_V2_MULTIPLICATION: "Create By Multiplication",
        USER_CREATED_TOOL_V2_DIVISION: "Create By Division",
    };

    static APP_NAME_LOGIN = {
        WORKSHEETZONE: "WorksheetZone",
    };
    static SUBJECTid = {
        MATH: "64781b8bbd014c24db77d90c",
        ELA: "64781c13bd014c24db77de8e",
    };
    static TOPICid = {
        WORD_SEARCH: "64795009b12c3817579a2f5b",
    };
    static LOCALSTORAGE_LOGIN = {
        DONE_SURVEY: "doneSurvey",
        IS_VERIFY: "isVerify",
        LOGIN_DATE: "loginDate",
    };
    static PROJECT_WEB = "PROJECT_WEB";
    static PROJECT_CLASSROOM = "PROJECT_CLASSROOM";
    static PROJECT_TOOL_CREATE = "PROJECT_TOOL_CREATE";
    static PROJECT_TOOL_V2 = "PROJECT_TOOL_V2";
    static ID_SVG_WSE = "puzzle_grid_svg_paint";
    static ELEMENT_WORD_FOUND_TOOL_V2 = "word_search_found";
    static ACTIVITY_TYPE = {
        WORD_SEARCH: {
            TITLE: "Word Search",
            TYPE: "WORD_SEARCH",
        },
        FILL_IN_BLANK: {
            TITLE: "Fill In The Blank",
            TYPE: "FILL_IN_BLANK",
        },
        HANDWRITING: {
            TITLE: "Handwriting",
            TYPE: "HANDWRITING",
        },
        MULTIPLE_CHOICES: {
            TITLE: "Multiple Choices",
            TYPE: "MULTIPLE_CHOICES",
        },
        NAME_TRACING: {
            TITLE: "Name Tracing",
            TYPE: "NAME_TRACING",
        },
        CUSTOMIZE_WS: {
            TITLE: "Customize Worksheet",
            TYPE: "CUSTOMIZE_WORKSHEET",
        },
        CROSSWORD: {
            TITLE: "Crossword",
            TYPE: "CROSSWORD",
        },
        ADDITION: {
            TITLE: "Addition",
            TYPE: "ADDITION",
        },
        SUBTRACTION: {
            TITLE: "Subtraction",
            TYPE: "SUBTRACTION",
        },
        MULTIPLICATION: {
            TITLE: "Multiplication",
            TYPE: "MULTIPLICATION",
        },
        DIVISION: {
            TITLE: "Division",
            TYPE: "DIVISION",
        },
        WORD_SCRAMBLE: {
            TITLE: "Word Scramble",
            TYPE: "WORD_SCRAMBLE",
        },
        SENTENCE_SCRAMBLE: {
            TITLE: "Sentence Scramble",
            TYPE: "SENTENCE_SCRAMBLE",
        },
    };
    static PROJECT_NAME = {
        WORD_SEARCH: "word-search",
        TOOL_V2: "tool-v2",
    };
    static BASE_CANVAS_WIDTH = 1000;

    static SOURCE_WORK_SHEET_ZONE = "worksheet-zone";
    static LIKE_WS = 1;
    static DISLIKE_WS = 0;
    static NONE_VOTE = -1;
    static CATEGORY_FROM_SEARCH = 0;
    static CATEGORY_FROM_SELECT = 1;

    // static INIT_QUOTA_DOWNLOAD = 1;
    static INIT_QUOTA_DOWNLOAD = 0;
    static INIT_TIMES_DOWNLOAD = 1;
    static MAX_TIMES_DOWNLOAD = 6;
    static INCREASE_QUOTA_DOWNLOAD = 5;
    static INFINITY_QUOTA_DOWNLOAD = 100000;
    static BONUS_QUOTA_DOWNLOAD_EACH_SHARE = 1;
    static BONUS_QUOTA_DOWNLOAD_EACH_CLAIM = 2;

    static BONUS_QUOTA_DOWNLOAD_FREE_CLAIM = 2;
    static BONUS_QUOTA_DOWNLOAD_EACH_SHARE_PIN = 2;
    static BONUS_QUOTA_DOWNLOAD_EACH_DAILY_TIME = 1;
    static BONUS_QUOTA_DOWNLOAD_EACH_INVITE_FRIEND = 10;
    static BONUS_QUOTA_DOWNLOAD_EACH_ACCEPT_INVITE_FRIEND = 5;
    static CLASS_DO_NOT_DELETE = "tracking-claim-exist-2006";

    static INVALID_EMAIL = 1;
    static INVALID_USER_CODE = 2;

    static DEFAULT_REFERRAL_STATUS = -1;
    static EXISTED_UESER = 3;
    static REFERRED = 4;
    static AVAILABLE_REFERER = 5;
    static NEW_USER_LOGIN_REFERRAL = 6;
    static LOGIN_REFERRAL = 7;
    static SENT_REFERRAL_MAIL = 8;

    static ACCEPTED_REFERR = 1;
    static SENT_REFERRAL = 2;
    static ACCEPTED_BY_OTHER_REFERR = 3;
    static STATUS_ALERT_DOWNLOAD = {
        INIT: -1,
        SHOW_SAFE: 1,
        SHOW_WARNING: 2,
        SHOW_WARNING_LIMIT: 3,
        SHOW_BONUS_DOWNLOAD_CENTER: 4,
        SHOW_BONUS_DOWNLOAD_CORNER: 5,
    };
    static LIMIT_STEP3 =
        Config.INIT_QUOTA_DOWNLOAD +
        Config.INCREASE_QUOTA_DOWNLOAD +
        Config.INCREASE_QUOTA_DOWNLOAD;
    static TOOLTIP_VALUE_PRINT = "print";
    static TOOLTIP_VALUE_DOWNLOAD = "download";
    static CONDITION_OPERATION = {
        AND: "and",
        OR: "or",
    };
    static STATUS_DONE_SURVEY_USER = {
        DONE: 1,
        NOT_DONE: 0,
    };
    static TOOL_V2_FILL_THE_BLANK_PATH = "fill-in-the-blank-generator";
    static INDEX_GOOGLE_STATUS = {
        SUBMITED: "submited",
        INDEXED: "indexed",
        NOT_INDEXED: "not-indexed",
    };

    static PATH_TOOL_V2_CUSTOMIZE_WORKSHEET = "customize-worksheet";
    static PATH_TOOL_V2_MULTIPLE_CHOICES = "multiple-choices-generator";

    static PATH_TOOL_V1_FEEDBACK = "/worksheet-maker#convert";

    static WARNING_REQUIRED = {
        TITLE: "Title",
        DESCRIPTION: "Description",
        LANGUAGE: "Language",
        GRADE: "Grade",
        TAG: "Tag",
    };
    static STYLE_SNACK_BAR = {
        INIT: { justifyContent: "center", alignItems: "center" },
    };
    static ANCHOR_ORIGIN_SNACK_BAR = {
        INIT: { vertical: "bottom", horizontal: "right" },
        CENTER: { vertical: "bottom", horizontal: "center" },
    };
    static WARNING_REQUIRED_TRACKING = [
        {
            field: Config.WARNING_REQUIRED.TITLE,
            messTracking: "title",
        },
        {
            field: Config.WARNING_REQUIRED.DESCRIPTION,
            messTracking: "description",
        },
        {
            field: Config.WARNING_REQUIRED.LANGUAGE,
            messTracking: "language",
        },
        {
            field: Config.WARNING_REQUIRED.GRADE,
            messTracking: "grade",
        },
        {
            field: Config.WARNING_REQUIRED.TAG,
            messTracking: "tag",
        },
    ];
    static LIST_ACTIVITY_SUGGEST_TAGS = [
        {
            type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
            categoryId: "64781b8abd014c24db77d8fa",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
            categoryId: "64781b64bd014c24db77d77d",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE,
            categoryId: "64795016b12c3817579a2fdf",
            originField: "topicIds",
        },
        {
            type: Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
            categoryId: "64795009b12c3817579a2f5b",
            originField: "topicIds",
        },
        {
            type: Config.ACTIVITY_TYPE.CROSSWORD.TYPE,
            categoryId: "64faf844acf6da56e5c9222a",
            originField: "topicIds",
        },
    ];
    static LIST_ACTIVITY_SUGGEST_TAGS_TOOL_V1 = [
        {
            type: ANSWER_TYPE.WORD_SEARCH,
            categoryId: "656450536bce803ff1cb644d",
            originField: "categoryIds",
        },
        {
            type: ANSWER_TYPE.JOIN,
            categoryId: "64795016b12c3817579a2fe2",
            originField: "topicIds",
        },
        {
            type: ANSWER_TYPE.SELECT,
            categoryId: "64795016b12c3817579a2fdc",
            originField: "topicIds",
        },
    ];

    static LIST_ACTIVITY_SUGGEST_TAGS_TOOL_V2 = [
        {
            type: Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
            categoryId: "64781b8abd014c24db77d8fa",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.HANDWRITING.TYPE,
            categoryId: "64781b64bd014c24db77d77d",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE,
            categoryId: "64795016b12c3817579a2fdf",
            originField: "topicIds",
        },
        {
            type: Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
            categoryId: "656450536bce803ff1cb644d",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.CROSSWORD.TYPE,
            categoryId: "64faf844acf6da56e5c9222a",
            originField: "topicIds",
        },
        {
            type: Config.ACTIVITY_TYPE.ADDITION.TYPE,
            categoryId: "64781b9fbd014c24db77d9d5",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.SUBTRACTION.TYPE,
            categoryId: "64781ba6bd014c24db77da1a",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE,
            categoryId: "64781bacbd014c24db77da5c",
            originField: "categoryIds",
        },
        {
            type: Config.ACTIVITY_TYPE.DIVISION.TYPE,
            categoryId: "64781bb1bd014c24db77da9b",
            originField: "categoryIds",
        },
    ];

    static SOURCE_USER_BEHAVIOR = {
        PICK_TEMPLATE: 0,
        CLICK_CUSTOMIZE_WS: 1,
        CREATE_FROM_SCRATCH: 2,
    };

    static CMS_FILTER_SOURCE_USER_BEHAVIOR = {
        PICK_TEMPLATE: "Pick Template",
        CLICK_CUSTOMIZE_WS: "Click Customize",
        CREATE_FROM_SCRATCH: "Create From Scratch",
    };

    static CMS_PAGE_RESOURCES = {
        WORKSHEET: "worksheet",
        SURVEY: "survey",
        CLASS: "class",
        REPORT_BUG: "report-bug",
        COMMENT: "comment",
        CATEGORY: "category",
        LESSON_PLAN: "lesson-plan",
        ACCOUNT: "account",
    };

    static CMS_FILTER_SURVEY_TYPE = {
        BROWSING: "Browsing",
        WORKSHEET_MAKER: "worksheetzone",
    };

    static CMS_FILTER_ROLE_USER = {
        NORMAL: "Normal",
        ADMIN: "Admin",
        CONTENT: "Content",
        TESTER: "Tester",
    };

    static CMS_TYPE_SEARCH = {
        KEYWORD: "Keyword",
        ID: "Search ID",
    };

    static CMS_UPDATE_RESOURCE = {
        EDIT: "Edit",
        DELETE: "Delete",
        INDEX: "Index",
        PUBLIC: "Public",
    };

    static EDIT_WS_MODE = {
        EDIT: 1,
        CLONE: 2,
    };

    static REPORT_BUGS = {
        FEEDBACK_HOME: "FEEDBACK_HOME",
    };
}
export const collectionIdDefaultList = [
    Config.COLLECTION_WORKSHEETS_CREATED.WORKSHEETS_CREATED_COLLECTIONid,
    Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid,
    Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid,
    Config.COLLECTION_DRAFT.DRAFT_COLLECTIONid,
];

export enum ChatAction {
    Next = "Next",
    ReGenerate = "ReGenerate",
    FakeContent = "FakeContent",
}
