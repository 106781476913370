import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hook';
import Button from 'react-bootstrap/Button';
import { updateDataAPI } from '../../../../utils/query';
import { useDispatch } from 'react-redux';
import { saveTemplate, saveWorksheet, setIdeaTemplate, setTemplate } from '../../../../redux/reducers/createWorksheet';
import { convertImagesToBase64 } from '../../../utils/convertImg';
import { saveDesignItems } from '../../../../utils';

import './styles.scss';
import { getTemplateFields } from '../../../../utils/template';
import moment from 'moment';
import { ButtonGroup, Dropdown, Modal } from 'react-bootstrap';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';

const SaveButton = () => {
    const dispatch = useDispatch();
    const { lastChangedTime, lastSavedTime, tempTemplateZones, autoSave, templateZoneDisplayMode, isIframe, disabledSaveButton } = useAppSelector(state => state.createWorksheetState);
    const { template, ideaTemplate, ideaAnswerSheetPage, ideaAnswerKeyPage, ideaThumbnail, pageType, designChanged } = useAppSelector(state => state.createWorksheetState);
    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems);

    const [status, setStatus] = useState(null);
    const [showImgModal, setShowImgModal] = useState(false);
    const [isPressedSaveButton, setIsPressedSaveButton] = useState(false);

    useEffect(() => {
        if (autoSave && !disabledSaveButton) {
            const interval = setInterval(() => {
                document.getElementById('save-button').click();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [autoSave, disabledSaveButton]);

    const saveThenExport = async () => {
        if (disabledSaveButton) return;
        dispatch(saveWorksheet());
        setIsPressedSaveButton(true);
        setStatus('Saving design...')
        try {
            await save();
            if (pageType !== ConstantsTool.TEMPLATE_TYPES['template'] && pageType !== ConstantsTool.TEMPLATE_TYPES['storeThumbnail']) {
                await exportWorksheet();
                if (pageType === 'page' && isIframe) {
                    sendMessageToStudioWhenExported(ideaTemplate.id);
                }
            }
            setStatus(null);
        } catch (err) {
            console.log('err', err);
            window.alert('Save Error. Please contact support!');
            setStatus(null);
        }
        console.log('save and export done');
        setIsPressedSaveButton(false);
    }

    const save = async () => {
        setStatus('Saving...');
        if (pageType === 'template') {
            const fields = getTemplateFields(template, resourceItems, tempTemplateZones);
            await updateDataAPI('POST', 'auto-content/generate-template-fields-format-from-fields-list/?templateID=' + template.id, fields);
        }
        const savedItems = await saveDesignItems(dispatch, pageType, resourceItems, template?.id, ideaTemplate?.id, ideaAnswerSheetPage?.id, ideaAnswerKeyPage?.id, ideaThumbnail?.id, tempTemplateZones);
        if (pageType === 'template') {
            const zones = resourceItems.filter(item => item.fieldName);
            await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                zones: zones.map(zone => ({
                    id: zone.id.indexOf('_') > -1 ? savedItems.find(item => item.attributes.originalID === zone.id).id : zone.id,
                    fieldName: zone.fieldName,
                    fieldIndex: zone.fieldIndex,
                    parentField: zone.parentField,
                    parentFieldIndex: zone.parentFieldIndex,
                })),
                answers: template.answerFields,
                templateID: template.id,
            });
        }
    }

    const exportWorksheet = async () => {
        setStatus('Exporting...');
        const imgData = await convertImagesToBase64('content-layer');
        try {
            const res = await updateDataAPI('POST', 'auto-content/convert-image-data-to-image-then-save/', {
                templateID: template?.id,
                ideaTemplateID: ideaTemplate?.id,
                ideaAnswerSheetPageID: ideaAnswerSheetPage?.id,
                ideaAnswerKeyPageID: ideaAnswerKeyPage?.id,
                ideaThumbnailID: ideaThumbnail?.id,
                imgData: imgData[0],
            });
            if (pageType === 'template') {
                dispatch(setTemplate({ ...template, imageUrl: res.data }));
            } else if (pageType === 'page') {
                dispatch(setIdeaTemplate({ ...ideaTemplate, imageUrl: res.data }));
            }
        } catch (e) {
            console.error(`An error occured when export worksheet: ${e.message}`);
        }
    }

    const saveTemplateChanges = async () => {
        setStatus('Saving template...');
        setIsPressedSaveButton(true);
        try {
            if (template && template.id) {
                const { testAIResponse, testAnswerKey, ...toSaveObj } = template;
                await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', toSaveObj);
            } else {
                const res = await updateDataAPI('POST', 'auto-content/templates/', { ...template, type: ConstantsTool.TEMPLATE_TYPES_IN_DATABASE[pageType] });
                window.location.href = `/?type=${pageType}&id=` + res.data['id'];
            }
            dispatch(saveTemplate());
        } catch (e) {
            window.alert(e);
        }
        setStatus(null);
        setIsPressedSaveButton(false);
    }

    const saveChanges = async () => {
        if (pageType === ConstantsTool.TEMPLATE_TYPES['template'] || pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail'] || pageType === ConstantsTool.TEMPLATE_TYPES['answerSheet']) {
            await saveTemplateChanges();
        }
        if (designChanged) {
            await saveThenExport();
        }
    };

    const sendMessageToStudioWhenExported = (targetID: string) => {
        const message = {
            type: 'EXPORTED_IFRAME',
            message: 'Exported iframe!',
            targetID,
        };
        try {
            console.log('Send message', message);
            window.parent.postMessage(message, process.env.REACT_APP_STUDIO_URL);
        } catch (err) {
            console.error(`An error occured when sending a message to the studio: ${err.message}`);
        }
    }

    return (
        <Dropdown as={ButtonGroup}>
            <Button id="save-button" variant={(moment(lastChangedTime).diff(moment(lastSavedTime), 'seconds') > 0 || lastSavedTime === null || status !== null) ? 'warning' : 'success'} size="sm"
                onClick={() => {
                    if (Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) && templateZoneDisplayMode !== 'zoneOnly') {
                        if (!isIframe) {
                            window.alert('Please switch to Show Zone mode before saving');
                        }
                    } else if (Object.values(ConstantsTool.PAGE_TYPES).includes(pageType) && templateZoneDisplayMode !== 'textOnly') {
                        if (!isIframe) {
                            window.alert('Please switch to Show Text mode before saving');
                        }
                    } else if (moment(lastChangedTime).diff(moment(lastSavedTime), 'seconds') > 0 || lastSavedTime === null) {
                        saveChanges();
                    }
                }}
                disabled={disabledSaveButton || isPressedSaveButton}>
                {status !== null ? status : ((moment(lastChangedTime).diff(moment(lastSavedTime), 'seconds') > 0 || lastSavedTime === null) ? 'Changes not saved' : 'All Changes Saved')}

                {pageType === 'template' && template?.imageUrl && (
                    <img src={template.imageUrl} className="img-thumbnail" onClick={() => setShowImgModal(true)} />
                )}
                {pageType === ConstantsTool.PAGE_TYPES['page'] && ideaTemplate?.imageUrl && (
                    <img src={ideaTemplate.imageUrl} className="img-thumbnail" onClick={() => setShowImgModal(true)} />
                )}
                {pageType === ConstantsTool.PAGE_TYPES['answerSheet'] && ideaAnswerSheetPage?.imageUrl && (
                    <img src={ideaAnswerSheetPage.imageUrl} className="img-thumbnail" onClick={() => setShowImgModal(true)} />
                )}
                {pageType === ConstantsTool.PAGE_TYPES['answerKey'] && ideaAnswerKeyPage?.imageUrl && (
                    <img src={ideaAnswerKeyPage.imageUrl} className="img-thumbnail" onClick={() => setShowImgModal(true)} />
                )}
                {pageType === ConstantsTool.PAGE_TYPES['thumbnail'] && ideaThumbnail?.imageUrl && (
                    <img src={ideaThumbnail.imageUrl} className="img-thumbnail" onClick={() => setShowImgModal(true)} />
                )}

                {showImgModal && (
                    <Modal
                        show={true}
                        onHide={() => setShowImgModal(false)}
                        contentLabel="Add Tag Modal"
                        className="final-result-modal"
                    >
                        <Modal.Body>
                            {pageType === 'template' && template?.imageUrl && (
                                <img src={template.imageUrl} className="img-thumbnail" />
                            )}
                            {pageType === ConstantsTool.PAGE_TYPES['page'] && ideaTemplate?.imageUrl && (
                                <img src={ideaTemplate.imageUrl} className="img-thumbnail" />
                            )}
                            {pageType === ConstantsTool.PAGE_TYPES['answerSheet'] && ideaAnswerSheetPage?.imageUrl && (
                                <img src={ideaAnswerSheetPage.imageUrl} className="img-thumbnail" />
                            )}
                            {pageType === ConstantsTool.PAGE_TYPES['answerKey'] && ideaAnswerKeyPage?.imageUrl && (
                                <img src={ideaAnswerKeyPage.imageUrl} className="img-thumbnail" />
                            )}
                            {pageType === ConstantsTool.PAGE_TYPES['thumbnail'] && ideaThumbnail?.imageUrl && (
                                <img src={ideaThumbnail.imageUrl} className="img-thumbnail" />
                            )}
                        </Modal.Body>
                    </Modal>
                )}
            </Button>
        </Dropdown>
    );
};
export default SaveButton;
